import axios from 'axios'
import { zeroFormat } from 'numeral'
import Vue from 'vue'

const GraphqlMutations = {}

async function makeRequest(query) {
    try {
        const request = await axios({
            url: 'https://api.torkhub.com/v1/graphql',
            method: 'post',
            headers: {
                'x-hasura-admin-secret': process.env.VUE_APP_HASURA_KEY
            },
            data: query
        }).then((result) => {
            if (result.data.errors) {
                return result.data.errors[0].message
            } else {
                return result.data
            }
        })
        const results = await request
        return results
    } catch (error) {
        this.$Sentry.captureException(error)
    }
}

function getGraphQLType(jsType, value) {
    switch (jsType) {
        case 'string':
            var isoRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d{3})?Z$/
            if (isoRegex.test(value)) {
                return 'timestamp'
            }
            return 'String'
        case 'number':
            return 'Int'
        case 'boolean':
            return 'Boolean'
        default:
            return 'String' // Default to String type if no match found
    }
}

GraphqlMutations.createUser = (data) => {
    const dateTime = (new Date()).toISOString().slice(0, 19).replace(/-/g, '/').replace('T', ' ')
    data.cre_dttm = dateTime

    const query = {
        operationName: 'insert_users',
        query: `mutation insert_users($cre_dttm: timestamp!, $email: String!, $cell_phone: String, $full_name: String!, $status: String = "unconfirmed", $preferred_name: String, $address_1: String, $address_2: String, $city: String, $state_province: String, $country: String, $first_name: String, $last_name: String) {
      insert_users(objects: {email: $email, cre_dttm: $cre_dttm, cell_phone: $cell_phone, full_name: $full_name, status: $status, preferred_name: $preferred_name, address_1: $address_1, address_2: $address_2, city: $city, state_province: $state_province, country: $country, first_name: $first_name, last_name: $last_name}) {
        affected_rows
        returning {
          user_id
          email
        }
      }
    }`,
        variables: data
    }
    return makeRequest(query)
}
GraphqlMutations.createUserWillCall = (data) => {
    const query = {
        operationName: 'insert_users',
        query: `mutation insert_users($cre_dttm: timestamp!, $email: String!, $cell_phone: String, $full_name: String!, $status: String = "unconfirmed", $first_name: String, $last_name: String) {
      insert_users(objects: {email: $email, cre_dttm: $cre_dttm, cell_phone: $cell_phone, full_name: $full_name, status: $status, first_name: $first_name, last_name: $last_name}) {
        affected_rows
        returning {
          user_id
          email
          first_name
          last_name
          cell_phone
          is_house_account
        }
      }
    }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlMutations.updateUserWillCall = (status) => {
    const query = {
        operationName: 'update_users',
        query: `mutation update_users($user_id: Int, $first_name: String, $last_name: String, $full_name: String, $cell_phone: String) {
      update_users(where: {user_id: {_eq: $user_id}}, _set: {cell_phone: $cell_phone, full_name: $full_name, first_name: $first_name, last_name: $last_name}) {
        affected_rows
        returning {
          user_id
          email
          first_name
          last_name
          cell_phone
          is_house_account
        }
      }
    }`,
        variables: status
    }
    return makeRequest(query)
}

GraphqlMutations.updateUserStatus = (status) => {
    const query = {
        operationName: 'update_users',
        query: `mutation update_users($user_id: Int, $status: String) {
      update_users(where: {user_id: {_eq: $user_id}}, _set: {status: $status}) {
        affected_rows
      }
    }`,
        variables: status
    }
    return makeRequest(query)
}

GraphqlMutations.updateUser = (updateInfo) => {
    const query = {
        operationName: 'update_users',
        query: `mutation update_users($user_id: Int, $first_name: String, $last_name: String, $cell_phone: String) {
      update_users(where: {user_id: {_eq: $user_id}}, _set: {first_name: $first_name, last_name: $last_name, cell_phone: $cell_phone}) {
        affected_rows
      }
    }`,
        variables: updateInfo
    }
    return makeRequest(query)
}

GraphqlMutations.updateUserCrew = (updateInfo) => {
    const query = {
        operationName: 'update_users',
        query: `mutation update_users($user_id: Int, $first_name: String, $last_name: String, $cell_phone: String, $full_name: String) {
      update_users(where: {user_id: {_eq: $user_id}}, _set: {first_name: $first_name, last_name: $last_name, cell_phone: $cell_phone, full_name: $full_name}){
        affected_rows
      }
    }`,
        variables: updateInfo
    }
    return makeRequest(query)
}

GraphqlMutations.createOrder = (orderInfo) => {
    const query = {
        operationName: 'insert_orders',
        query: `mutation insert_orders($user_id: Int, $org_id: Int = ${Vue.prototype.$org.org_id}, $price: numeric, $tax: numeric, $total: numeric, $cre_dttm: timestamp, $product_id: Int, $user_id1: Int, $order_number: Int, $assigned_dttm: timestamp) {
      insert_orders(objects: {org_id: $org_id, price: $price, status: "Pending", total: $total, tax: $tax, cre_dttm: $cre_dttm, user_id: $user_id, order_number: $order_number, order_items: {data: {product_id: $product_id, user_id: $user_id1, assigned_dttm: $assigned_dttm}}}) {
        affected_rows
        }
      }`,
        variables: orderInfo
    }
    return makeRequest(query)
}

GraphqlMutations.createOrderItem = (data) => {
    const query = {
        operationName: 'insert_order_items',
        query: `mutation insert_order_items($assigned_dttm: timestamp, $create_dttm: timestamp, $order_number: Int, $product_id: Int, $user_id: Int, $status: String, $price: numeric, $quantity: Int, $assigned_user_id: Int, $notes: String, $pay_plan: Boolean, $event_product_id: Int, $wristband_id: String, $submitted_dttm: timestamp, $discount: numeric, $discount_reason: String, $delivery_method: jsonb, $item_fee_1: numeric, $item_fee_2: numeric, $item_fee_3: numeric, $item_fee_4: numeric, $item_fee_5: numeric, $item_platform_fee: numeric, $item_cc_fee: numeric: $team_id: Int, $partner_id: Int, $media_outlet_id: Int, $purchaser_can_transfer: Boolean, $attendee_can_transfer: Boolean, $allocation_id: Int, $source_channel: String ) {
      insert_order_items(objects: {created_dttm: $created_dttm, order_number: $order_number, product_id: $product_id, user_id: $user_id, status: $status, price: $price, quantity: $quantity, assigned_user_id: $assigned_user_id, assigned_dttm: $assigned_dttm, notes: $notes, pay_plan: $pay_plan, event_product_id: $event_product_id, wristband_id: $wristband_id, submitted_dttm: $submitted_dttm, discount: $discount, discount_reason: $discount_reason, delivery_method: $delivery_method, item_fee_1: $item_fee_1, item_fee_2: $item_fee_2, item_fee_3: $item_fee_3, item_fee_4: $item_fee_4, item_fee_5: $item_fee_5, item_platform_fee: $item_platform_fee, item_cc_fee: $item_cc_fee, team_id: $team_id, partner_id: $partner_id, media_outlet_id: $media_outlet_id, purchaser_can_transfer: $purchaser_can_transfer, attendee_can_transfer: $attendee_can_transfer, allocation_id: $allocation_id, source_channel: $source_channel}) {
        returning {
          order_items_id
        }
      }
    }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlMutations.createOrderItem2 = (data) => {
    const query = {
        operationName: 'insert_order_items',
        query: `mutation insert_order_items($data: [order_items_insert_input!] = {}) {
      insert_order_items(objects: $data) {
        returning {
          order_items_id
        }
      }
    }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlMutations.deleteOrder = (orderNumber) => {
    const query = {
        operationName: 'delete_orders',
        query: `mutation delete_orders($order_number: Int) {
      delete_order_items(where: {order_number: {_eq: $order_number}}){
        affected_rows
      }
      delete_orders(where: {order_number: {_eq: $order_number}}) {
        affected_rows
      }
    }`,
        variables: {
            order_number: orderNumber
        }
    }
    return makeRequest(query)
}
GraphqlMutations.createRequirementItems = (items) => {
    const query = {
        operationName: 'insert_order_requirement_items',
        query: `mutation insert_order_requirement_items($order_items_id: Int, $assigned_user_id: Int, $due_date: date, $status: String, $req_id: Int) {
      insert_order_requirement_items(objects: {order_items_id: $order_items_id, assigned_user_id: $assigned_user_id, due_date: $due_date, status: $status, req_id: $req_id}) {
        affected_rows
      }
    }`,
        variables: items
    }
    return makeRequest(query)
}

GraphqlMutations.createProductItems = (items) => {
    const query = {
        operationName: 'insert_order_fulfillment_items',
        query: `mutation insert_order_fulfillment_items($product_id: Int, $order_items_id: Int, $fulfillment_method: String, $fulfilled_quantity: Int, $quantity: Int, $is_media: Boolean = false) {
      insert_order_fulfillment_items(objects: {product_id: $product_id, order_items_id: $order_items_id, fulfillment_method: $fulfillment_method, fulfilled_quantity: $fulfilled_quantity, quantity: $quantity, is_media: $is_media}) {
        affected_rows
      }
    }`,
        variables: items
    }
    return makeRequest(query)
}

GraphqlMutations.updateMediaNumber = (data) => {
    const query = {
        operationName: 'updateMediaNumber',
        query: `mutation updateMediaNumber($fulfillment_items_id: Int!, $media_number: String, $status: String) {
      update_order_fulfillment_items_by_pk(pk_columns: {fulfillment_items_id: $fulfillment_items_id}, _set: {media_number: $media_number, status: $status}) {
        fulfilled_quantity
        fulfillment_method
        is_media
        media_number
        product_id
        quantity
        status
      }
    }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlMutations.updateOrderItemMediaStatus = (data) => {
    const query = {
        operationName: 'updateOrderItemMediaStatus',
        query: `mutation updateOrderItemMediaStatus($order_items_id: Int!, $media_status: String) {
      update_order_items_by_pk(pk_columns: {order_items_id: $order_items_id}, _set: {media_status: $media_status}) {
        order_items_id
        media_status
      }
    }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlMutations.removeOrderFulfillmentItem = (item) => {
    const query = {
        operationName: 'removeOrderFulfillmentItem',
        query: `mutation removeOrderFulfillmentItem($fulfillment_items_id: Int!) {
      delete_order_fulfillment_items_by_pk(fulfillment_items_id: $fulfillment_items_id) {
        order_items_id
      }
    }`,
        variables: item
    }
    return makeRequest(query)
}

GraphqlMutations.updateOrderFulfillmentItemsQtyStatus = (item) => {
    const query = {
        operationName: 'update_order_fulfillment_items',
        query: `mutation update_order_fulfillment_items($fulfilled_quantity: Int, $status: String, $fulfillment_items_id: Int) {
      update_order_fulfillment_items(where: {fulfillment_items_id: {_eq: $fulfillment_items_id}}, _set: {fulfilled_quantity: $fulfilled_quantity, status: $status}) {
        affected_rows
      }
    }`,
        variables: item
    }
    return makeRequest(query)
}

GraphqlMutations.updateOrderItemsStatus = (data) => {
    const query = {
        operationName: 'update_order_items',
        query: `mutation update_order_items($order_items_id: Int, $status: String = "Submitted", $submitted_dttm: timestamp ) {
      update_order_items(_set: {status: $status, submitted_dttm: $submitted_dttm}, where: {order_items_id: {_eq: $order_items_id}}) {
        affected_rows
      }
    }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlMutations.updateOrderItemsAssigned = (data) => {
    const query = {
        operationName: 'update_order_items',
        query: `mutation update_order_items($assigned_user_id: Int, $order_items_id: Int, $assigned_dttm: timestamp) {
      update_order_items(where: {order_items_id: {_eq: $order_items_id}}, _set: {assigned_user_id: $assigned_user_id, assigned_dttm: $assigned_dttm}) {
        affected_rows
      }
    }`,
        variables: data
    }
    return makeRequest(query)
}

/*
{
  "product_id": 23,
  "quantity": -1
}
*/
GraphqlMutations.updateProductQuantity = (productInfo) => {
    const query = {
        operationName: 'update_products',
        query: `mutation update_products($quantity: Int, $product_id: Int) {
      update_products(where: {product_id: {_eq: $product_id}}, _inc: {quantity: $quantity}) {
        affected_rows
      }
    }`,
        variables: productInfo
    }
    return makeRequest(query)
}

GraphqlMutations.updateOnlyEventProductQuantity = (productInfo) => {
    const query = {
        operationName: 'update_event_products',
        query: `mutation update_event_products($quantity: Int, $event_product_id: Int) {
      update_event_products(_inc: {quantity: $quantity}, where: {event_products_id: {_eq: $event_product_id}}) {
          affected_rows
      }
      }`,
        variables: productInfo
    }
    return makeRequest(query)
}

GraphqlMutations.updateEventProductsQuantity = (productInfo) => {
    const query = {
        operationName: 'update_event_products_qty',
        query: `mutation update_event_products_qty($quantity: Int, $max_quantity: Int, $event_products_id: Int, $status: String, $price: numeric, $guest_tickets_price: numeric, $boxoffice_price: numeric, $display_start_date: date, $display_end_date: date, $access_start: date, $access_end: date) {
      update_event_products(where: {event_products_id: {_eq: $event_products_id}}, _set: {quantity: $quantity, max_quantity: $max_quantity, status: $status, price: $price, guest_tickets_price: $guest_tickets_price, boxoffice_price: $boxoffice_price, display_start_date: $display_start_date, display_end_date: $display_end_date, access_start: $access_start, access_end: $access_end}) {
        affected_rows
      }
    }`,
        variables: productInfo
    }
    return makeRequest(query)
}

GraphqlMutations.updateEventProductsAccountingCode = (productInfo) => {
    const query = {
        operationName: 'update_event_products_account_code',
        query: `mutation update_event_products_account_code($accounting_code: String, $event_products_id: Int) {
      update_event_products(where: {event_products_id: {_eq: $event_products_id}}, _set: {accounting_code: $accounting_code}) {
        affected_rows
      }
    }`,
        variables: productInfo
    }
    return makeRequest(query)
}

GraphqlMutations.updateAllocations = (allocationInfo) => {
    const query = {
        operationName: 'update_allocations',
        query: `mutation update_allocations($allocation_id: Int, $max_quantity: Int) {
      update_allocations(where: {allocation_id: {_eq: $allocation_id}}, _set: {max_qty: $max_quantity}) {
        affected_rows
      }
    }`,
        variables: allocationInfo
    }
    return makeRequest(query)
}

/*
 Example data object
  {
  "status":"active",
  "team_name": "Initial D",
  "org_id": 1,
  "team_website": "InitialD.com",
  "team_facebook": null,
  "team_youtube_url": null,
  "team_instagram": null
}
*/
GraphqlMutations.createTeam = (teamInfo) => {
    const query = {
        operationName: 'insert_teams',
        query: `mutation insert_teams($status: String, $team_name: String, $org_id: Int = ${Vue.prototype.$org.org_id}, $team_facebook_url: String,$team_website: String, $team_instagram_url: String, $team_youtube_url: String) {
      insert_teams(objects: {status: $status, team_name: $team_name, org_id: $org_id, team_facebook_url: $team_facebook_url, team_website: $team_website, team_instagram_url: $team_instagram_url, team_youtube_url: $team_youtube_url}) {
        returning {
          team_id
        }
      }
    }`,
        variables: teamInfo
    }
    return makeRequest(query)
}

GraphqlMutations.updateTeam = (updateTeamInfo) => {
    const query = {
        operationName: 'update_teams',
        query: `mutation update_teams($status: String, $team_name: String, $team_id: Int) {
      update_teams(_set: {status: $status, team_name: $team_name}, where: {team_id: {_eq: $team_id}}) {
        affected_rows
      }
    }`,
        variables: updateTeamInfo
    }
    return makeRequest(query)
}

/*
{
  "team_id": 4,
  "user_id": 2,
  "role_id": 4,
  "status": "active"
}
*/
GraphqlMutations.createTeamUser = (teamUserInfo) => {
    const query = {
        operationName: 'insert_team_users',
        query: `mutation insert_team_users($team_id: Int, $status: String, $user_id: Int, $role_id: Int, $purchase_tickets: Boolean = false, $request_credential: Boolean = false) {
      insert_team_users(objects: {team_id: $team_id, status: $status, user_id: $user_id, role_id: $role_id, purchase_tickets: $purchase_tickets, request_credential: $request_credential}) {
        returning {
          team_user_id
        }
      }
    }`,
        variables: teamUserInfo
    }
    return makeRequest(query)
}

GraphqlMutations.updateTeamUserStatus = (data) => {
    const query = {
        operationName: 'update_team_users',
        query: `mutation update_team_users($team_user_id: Int, $status: String) {
      update_team_users(where: {team_user_id: {_eq: $team_user_id}}, _set: {status: $status}) {
        affected_rows
      }
    }`,
        variables: data
    }
    return makeRequest(query)
}
GraphqlMutations.updateTeamUserToAdmin = (data) => {
    const query = {
        operationName: 'update_team_users',
        query: `mutation update_team_users($team_user_id: Int, $status: String, $role_id: Int) {
      update_team_users(where: {team_user_id: {_eq: $team_user_id}}, _set: {status: $status, role_id: $role_id}) {
        affected_rows
      }
    }`,
        variables: data
    }
    return makeRequest(query)
}
GraphqlMutations.updateTeamUserGuestTickets = (data) => {
    const query = {
        operationName: 'update_team_users',
        query: `mutation update_team_users($purchase_tickets: Boolean, $team_user_id: Int) {
      update_team_users(where: {team_user_id: {_eq: $team_user_id}}, _set: {purchase_tickets: $purchase_tickets}) {
        affected_rows
      }
    }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlMutations.createTeamPosition = (positionInfo) => {
    const query = {
        operationName: 'insert_team_positions',
        query: `mutation insert_team_positions($position_id: Int, $team_user_id: Int) {
      insert_team_positions(objects: {position_id: $position_id, team_user_id: $team_user_id}) {
        affected_rows
      }
    }`,
        variables: positionInfo
    }
    return makeRequest(query)
}

GraphqlMutations.deleteTeamPosition = (data) => {
    const query = {
        operationName: 'delete_team_positions',
        query: `mutation delete_team_positions($team_user_id: Int, $position_id: Int) {
      delete_team_positions(where: {team_user_id: {_eq: $team_user_id}, _and: {position_id: {_eq: $position_id}}}) {
        affected_rows
      }
    }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlMutations.deleteAllTeamPositionsByTeamUserId = (data) => {
    const query = {
        operationName: 'delete_team_positions',
        query: `mutation delete_team_positions($team_user_id: Int) {
      delete_team_positions(where: {team_user_id: {_eq: $team_user_id}}) {
        affected_rows
      }
    }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlMutations.upDateTeamUserPrimaryPosition = (data) => {
        const query = {
            operationName: 'update_team_users_primary_position',
            query: `mutation update_team_users_primary_position($primary_position: Int, $team_user_id: Int) {
      update_team_users(where: {team_user_id: {_eq: $team_user_id}}, _set: {primary_position: $primary_position}) {
        affected_rows
      }
    }`,
            variables: data
        }
        return makeRequest(query)
    }
    // GraphqlMutations.deleteTeamUser = (teamUserInfo) => {
    //   const query = {
    //     operationName: 'delete_team_users',
    //     query: `mutation delete_team_users($team_id: Int, $user_id: Int) {
    //       delete_team_users(where: {team_id: {_eq: $team_id}, _and: {user_id: {_eq: $user_id}}}) {
    //         affected_rows
    //       }
    //     }`,
    //     variables: teamUserInfo
    //   }
    //   return makeRequest(query)
    // }

GraphqlMutations.insertOrderRequirementItem = (data) => {
    const query = {
        operationName: 'insert_order_requirement_items',
        query: `mutation insert_order_requirement_items($assigned_user_id: Int, $due_date: date, $order_items_id: Int, $req_id: Int, $status: String) {
      insert_order_requirement_items(objects: {assigned_user_id: $assigned_user_id, due_date: $due_date, order_items_id: $order_items_id, req_id: $req_id, status: $status}) {
        affected_rows
        returning {
          order_requirement_items_id
          requirement {
            req_id
            req_name
          }
        }
      }
    }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlMutations.createHardCard = (data) => {
    const query = {
        operationName: 'insert_hard_cards',
        query: `mutation insert_hard_cards($org_id: Int = ${Vue.prototype.$org.org_id}, $status: String, $user_id: Int, $expiration: timestamp, $order_items_id: Int, $assigned_user: Int, $hard_card_type: String = "", $print_title: String = "", $team_id: Int = 1, $hard_card_level_id: Int) {
      insert_hard_cards(objects: {status: $status, org_id: $org_id, user_id: $user_id, assigned_user: $assigned_user, order_items_id: $order_items_id, expiration: $expiration, team_id: $team_id, print_title: $print_title, hard_card_type: $hard_card_type, hard_card_level_id: $hard_card_level_id}) {
        affected_rows
      }
    }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlMutations.updateHardCard = (data) => {
    const query = {
        operationName: 'update_hard_cards',
        query: `mutation update_hard_cards($status: String, $hard_card_id: Int, $assigned_user: Int) {
      update_hard_cards(_set: {status: $status, assigned_user: $assigned_user}, where: {hard_card_id: {_eq: $hard_card_id}}) {
        affected_rows
      }
    }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlMutations.deleteHardCard = (data) => {
    const query = {
        operationName: 'delete_hard_cards',
        query: `mutation delete_hard_cards($org_id: Int = ${Vue.prototype.$org.org_id}, $hard_card_id: Int) {
      delete_hard_cards(where: {org_id: {_eq: $org_id}, _and: {hard_card_id: {_eq: $hard_card_id}}}) {
        affected_rows
      }
    }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlMutations.insertDriverProfile = (data) => {
    const query = {
        operationName: 'insert_driver_profiles',
        query: `mutation insert_driver_profiles($bio: String, $first_name: String, $last_name: String, $birthday: date, $hometown_city: String, $hometown_state: String, $hometown_country: bpchar, $current_city: String, $current_state: String, $current_country: String, $driver_facebook_url: String, $driver_twitter_url: String, $driver_instagram_url: String, $driver_youtube_url: String, $driver_website_url: String, $status: String, $languages_spoken: String, $nationality: String, $driver_headshot: String, $team_user_id: Int, $org_id: Int = ${Vue.prototype.$org.org_id}) {
            insert_driver_profiles(objects: {bio: $bio, first_name: $first_name, last_name: $last_name, birthday: $birthday, hometown_city: $hometown_city, hometown_state: $hometown_state, hometown_country: $hometown_country, current_city: $current_city, current_state: $current_state, current_country: $current_country, driver_facebook_url: $driver_facebook_url, driver_twitter_url: $driver_twitter_url, driver_instagram_url: $driver_instagram_url, driver_youtube_url: $driver_youtube_url, languages_spoken: $languages_spoken, nationality: $nationality, driver_headshot: $driver_headshot, team_user_id: $team_user_id, org_id: $org_id, status: $status, driver_website_url: $driver_website_url}) {
              affected_rows
              returning {
                driver_profile_id
              }
            }
          }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlMutations.insertTeamProfile = (data) => {
    const query = {
        operationName: 'insert_team_profiles',
        query: `mutation insert_team_profiles($team_name: String, $team_website: String, $team_facebook_url: String, $team_instagram_url: String, $team_youtube_url: String, $team_twitter_url: String, $team_sponsors: String, $team_logo: String, $status: String) {
        insert_team_profiles(objects: {team_name: $team_name, team_website: $team_website, team_facebook_url: $team_facebook_url, team_instagram_url: $team_instagram_url, team_youtube_url: $team_youtube_url, team_twitter_url: $team_twitter_url, team_sponsors: $team_sponsors team_logo: $team_logo, status: $status}) {
          affected_rows
          returning {
            team_profile_id
          }
        }
      }`,
        variables: data
    }
    return makeRequest(query)
}

/* {
 "year": "2018",
  "make": "Ford",
  "model": "Flex",
  "vehicle_alias": "Hurst",
  "tires": "Toyo",
  "suspension": "Red9",
  "status": "active",
} */
GraphqlMutations.insertDriverPhysical = (driverPhysical) => {
    const query = {
        operationName: 'insert_driver_physical',
        query: `mutation insert_driver_physical($physical_form_url: String, $team_user_id: Int, $org_id: Int = ${Vue.prototype.$org.org_id}) {
        insert_driver_physical(objects: {physical_form_url: $physical_form_url, team_user_id: $team_user_id, org_id: $org_id}) {
          affected_rows
          returning {
            physical_id
          }
        }
      }`,
        variables: driverPhysical
    }
    return makeRequest(query)
}

GraphqlMutations.insertVehicle = (vehicleInfo) => {
    const query = {
        operationName: 'insert_vehicles',
        query: `mutation insert_vehicles($engine_manufacturer: String, $make: String, $model: String, $year: String, $vehicle_alias: String, $tire_manufacturer: String, $suspension: String, $horsepower: String, $exterior: String, $interior: String, $vehicle_photos: String, $vehicle_sponsors: String, $official_vehicle_number: Int) {
      insert_vehicles(objects: { engine_manufacturer: $engine_manufacturer, make: $make, model: $model, suspension: $suspension, tire_manufacturer: $tire_manufacturer, vehicle_alias: $vehicle_alias, year: $year, horsepower: $horsepower, exterior: $exterior, interior: $interior, vehicle_photos: $vehicle_photos, vehicle_sponsors: $vehicle_sponsors, official_vehicle_number: $official_vehicle_number }) {
        affected_rows
        returning {
          vehicle_id
        }
      }
    }`,
        variables: vehicleInfo
    }
    return makeRequest(query)
}

// GraphqlMutations.insertTeamVehicle = (teamVehicle) => {
//   const query = {
//     operationName: 'insert_vehicles',
//     query: `mutation insert_teams($status: String = "Unapproved", $team_name: String, $org_id: Int = ${Vue.prototype.$org.org_id}, $team_website: String, $team_instagram_url: String, $team_facebook_url: String, $team_twitter_url: String, $team_youtube_url: String, $team_sponsors: String, $team_logo: String = "") {
//         insert_teams(objects: {status: $status, team_name: $team_name, org_id: $org_id, team_website: $team_website, team_instagram_url: $team_instagram_url, team_facebook_url: $team_facebook_url, team_twitter_url: $team_twitter_url, team_youtube_url: $team_youtube_url, team_sponsors: $team_sponsors, team_logo: $team_logo}) {
//             returning {
//             team_id
//             }
//         }
//     }`,
//     variables: teamVehicle
//   }
//   return makeRequest(query)
// }

GraphqlMutations.updateVehicle = (vehicleInfo) => {
    const query = {
        operationName: 'update_vehicles',
        query: `mutation update_vehicles($vehicle_id: Int, $engine: String, $make: String, $model: String, $status: String, $suspension: String, $tires: String, $vehicle_alias: String, $year: String) {
      update_vehicles(where: {vehicle_id: {_eq: $vehicle_id}}, _set: {engine: $engine, make: $make, model: $model, status: $status, suspension: $suspension, tires: $tires, vehicle_alias: $vehicle_alias, year: $year}) {
        affected_rows
      }
    }`,
        variables: vehicleInfo
    }
    return makeRequest(query)
}

GraphqlMutations.deleteVehicle = (vehicleId) => {
    const query = {
        operationName: 'delete_vehicles',
        query: `mutation delete_vehicles($_eq: Int) {
      delete_vehicles(where: {vehicle_id: {_eq: $_eq}}) {
        affected_rows
      }
    }`,
        variables: vehicleId
    }
    return makeRequest(query)
}

GraphqlMutations.getVehicle = (vehicleInfo) => {
    const query = {
        operationName: 'get_team_vehicles',
        query: `query get_team_vehicles($team_id: Int, $vehicle_alias: String = "") {
      vehicles(where: {team_id: {_eq: $team_id}, _and: {vehicle_alias: {_ilike: $vehicle_alias}}}) {
        driver_id
        engine
        make
        model
        org_id
        status
        suspension
        team_id
        tires
        vehicle_alias
        vehicle_id
        year
      }
    }`,
        variables: vehicleInfo
    }
    return makeRequest(query)
}

GraphqlMutations.insertPitSpace = (pitSpaceInfo) => {
    const query = {
        operationName: 'insert_pit_space',
        query: `mutation insert_pit_space($agree_pit_rules: String, $awning: String, $awning_side: String, $driverside_awning_dimensions_0_0: numeric, $driverside_awning_dimensions_1_0: numeric, $driverside_awning_photos: String, $merch_photos: String, $passengerside_awning_dimensions_0_0: numeric, $passengerside_awning_dimensions_1_0: numeric, $passengerside_awning_photos: String, $pit_setup: String, $rig_dimensions_0_0: numeric, $rig_dimensions_1_0: numeric, $rig_photos: String, $selling_merch: String, $sharing_pit: String, $sharing_pit_with: String, $tents: String, $trailer_type: String, $truck_trailer_dimensions_0_0: numeric, $truck_trailer_dimensions_1_0: numeric, $truck_trailer_photos: String, $team_id: Int) {
        insert_pit_space(objects: {agree_pit_rules: $agree_pit_rules, awning: $awning, awning_side: $awning_side, driverside_awning_dimensions_0_0: $driverside_awning_dimensions_0_0, driverside_awning_dimensions_1_0: $driverside_awning_dimensions_1_0, driverside_awning_photos: $driverside_awning_photos, merch_photos: $merch_photos, passenger_side_awning_dimensions_0_0: $passengerside_awning_dimensions_0_0, passenger_side_awning_dimensions_1_0: $passengerside_awning_dimensions_1_0, passengerside_awning_photos: $passengerside_awning_photos, pit_setup: $pit_setup, rig_photos: $rig_photos, rig_dimensions_0_0: $rig_dimensions_0_0, rig_dimensions_1_0: $rig_dimensions_1_0, selling_merch: $selling_merch, sharing_pit: $sharing_pit, sharing_pit_with: $sharing_pit_with, tents: $tents, trailer_type: $trailer_type,  truck_trailer_dimensions_0_0: $truck_trailer_dimensions_0_0, truck_trailer_dimensions_1_0: $truck_trailer_dimensions_1_0, truck_trailer_photos: $truck_trailer_photos, team_id: $team_id }) {
          affected_rows
          returning {
            pit_space_id
          }
        }
      }`,
        variables: pitSpaceInfo
    }
    return makeRequest(query)
}

GraphqlMutations.update_order_requirement_items = (requirementInfo) => {
    const query = {
        operationName: 'update_order_requirement_items',
        query: `mutation update_order_requirement_items($ori: Int, $submission_data: jsonb, $submitted_by: Int, $submitted_dttm: timestamptz, $status: String) {
      update_order_requirement_items(where: {order_requirement_items_id: {_eq: $ori}}, _set: {submission_data: $submission_data, submitted_by: $submitted_by, submitted_dttm: $submitted_dttm, status: $status}) {
        affected_rows
      }
    }`,
        variables: requirementInfo
    }
    return makeRequest(query)
}

GraphqlMutations.updateOrderRequirementAssignment = (data) => {
    const query = {
        operationName: 'update_order_requirement_items',
        query: `mutation update_order_requirement_items($ori: Int, $assigned_user_id: Int) {
      update_order_requirement_items(where: {order_requirement_items_id: {_eq: $ori}}, _set: {assigned_user_id: $assigned_user_id}) {
        affected_rows
      }
    }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlMutations.update_order_requirement_items_status_notes = (requirementInfo) => {
    const query = {
        operationName: 'update_order_requirement_items',
        query: `mutation update_order_requirement_items($order_req_items_id: Int, $status: String, $notes: String) {
      update_order_requirement_items(where: {order_requirement_items_id: {_eq: $order_req_items_id}}, _set: {status: $status, notes: $notes}) {
        affected_rows
      }
    }`,
        variables: requirementInfo
    }
    return makeRequest(query)
}

GraphqlMutations.update_task_status_notes = (taskInfo) => {
    const query = {
        operationName: 'update_task_status_notes',
        query: `mutation update_task_status_notes($task_id: Int, $status: String, $note: String) {
          update_tasks(where: {task_id: {_eq: $task_id}}, _set: {note: $note, status: $status}) {
            affected_rows
          }
        }`,
        variables: taskInfo
    }
    return makeRequest(query)
}

GraphqlMutations.update_order_items_status = (statusInfo) => {
    const query = {
        operationName: 'update_order_items',
        query: `mutation update_order_items($order_items_id: Int, $status: String = "submitted", $notes: String = "") {
      update_order_items(_set: {status: $status, notes: $notes}, where: {order_items_id: {_eq: $order_items_id}}) {
        affected_rows
      }
    }`,
        variables: statusInfo
    }
    return makeRequest(query)
}

GraphqlMutations.updateOrderItemsAssignment = (data) => {
    const query = {
        operationName: 'update_order_items_assignment',
        query: `mutation update_order_items_assignment($order_items_id: Int, $assigned_user_id: Int, $assigned_dttm: timestamp) {
      update_order_items(_set: {assigned_user_id: $assigned_user_id, assigned_dttm: $assigned_dttm}, where: {order_items_id: {_eq: $order_items_id}}) {
        affected_rows
      }
    }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlMutations.cancelUpdateOrderItemStatus = (data) => {
    const query = {
        operationName: 'update_order_items',
        query: `mutation update_order_items($status: String, $order_items_id: Int, $updated_dttm: timestamp, $payment_status: String) {
          update_order_items(where: {order_items_id: {_eq: $order_items_id}}, _set: {status: $status, updated_dttm: $updated_dttm, payment_status: $payment_status}) {
              affected_rows
          }
      }`,
        variables: data
    }
    return ECU.makeRequest(query)
}

GraphqlMutations.updateOrderItemsAssignmentAndStatus = (data) => {
    const query = {
        operationName: 'update_order_items_assignment',
        query: `mutation update_order_items_assignment($order_items_id: Int, $assigned_user_id: Int, $assigned_dttm: timestamp, $status: String = "Pending") {
      update_order_items(_set: {assigned_user_id: $assigned_user_id, assigned_dttm: $assigned_dttm, status: $status}, where: {order_items_id: {_eq: $order_items_id}}) {
        affected_rows
      }
    }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlMutations.updateOrderRequirementAssignmentAndStatus = (data) => {
    const query = {
        operationName: 'update_order_requirement_items',
        query: `mutation update_order_requirement_items($assigned_user_id: Int, $status: String = "pending", $order_item_id: Int) {
        update_order_requirement_items(where: {order_items_id: {_eq: $order_item_id}}, _set: {assigned_user_id: $assigned_user_id, status: $status}) {
          affected_rows
        }
      }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlMutations.updateOrderItemsToWristBand = (data) => {
    const query = {
        operationName: 'update_order_items',
        query: `mutation update_order_items($order_items_id: Int, $event_product_id: Int, $product_id: Int, $note: String) {
      update_order_items(_set: {event_product_id: $event_product_id, product_id: $product_id, notes: $note}, where: {order_items_id: {_eq: $order_items_id}}) {
        affected_rows
      }
    }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlMutations.updateOrderItemsStatus = (data) => {
    const query = {
        operationName: 'update_order_items_assignment',
        query: `mutation update_order_items_assignment($order_items_id: Int, $updated_dttm: timestamp, $status: String) {
      update_order_items(_set: { updated_dttm: $updated_dttm, status: $status}, where: {order_items_id: {_eq: $order_items_id}}) {
        affected_rows
      }
    }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlMutations.updateOrderItemsCheckInNotes = (data) => {
    const query = {
        operationName: 'update_order_items_check_in_notes',
        query: `mutation update_order_items_check_in_notes($order_items_id: Int, $updated_dttm: timestamp, $check_in_notes: jsonb) {
      update_order_items(_set: { updated_dttm: $updated_dttm, check_in_note: $check_in_notes}, where: {order_items_id: {_eq: $order_items_id}}) {
        affected_rows
      }
    }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlMutations.updatePartnerData = (data) => {
    const query = {
        operationName: 'update_teams',
        query: `mutation update_teams($team_id: Int, $status: String, $guest_ticket_access: Boolean, $crew_access: Boolean) {
      update_teams(where: {team_id: {_eq: $team_id}}, _set: {status: $status, guest_ticket_access: $guest_ticket_access, crew_access: $crew_access }) {
        affected_rows
      }
    }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlMutations.updateOrderItemsWristband = (data) => {
    const query = {
        operationName: 'update_order_items',
        query: `mutation update_order_items($wristband_id: String, $updated_dttm: timestamp, $order_items_id: Int) {
      update_order_items(_set: {wristband_id: $wristband_id, updated_dttm: $updated_dttm}, where: {order_items_id: {_eq: $order_items_id}}) {
        affected_rows
      }
    }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlMutations.cancelOrderItemWillCall = (data) => {
    const query = {
        operationName: 'cancelOrderItemWillCall',
        query: `mutation cancelOrderItemWillCall($order_items_id: Int, $notes: String, $updated_dttm: timestamp) {
      update_order_items(where: {order_items_id: {_eq: $order_items_id}}, _set: {status: "canceled", notes: $notes, updated_dttm: $updated_dttm}) {
        affected_rows
        returning {
          order_items_id
        }
      }
    }
    `,
        variables: data
    }
    return makeRequest(query)
}

GraphqlMutations.openBoxOfficeTerminal = (data) => {
    const query = {
        operationName: 'openBoxOfficeTerminal',
        query: `mutation openBoxOfficeTerminal($boxoffice_terminal_id: Int!, $opened_by: Int, $opening_amount: numeric) {
        update_boxoffice_terminals_by_pk(pk_columns: {boxoffice_terminal_id: $boxoffice_terminal_id}, _set: {opened_by: $opened_by, opening_amount: $opening_amount, status: "opened"}) {
          boxoffice_terminal_id
          closing_amount
          created_at
          opened_by
          opening_amount
          org_id
          status
          updated_dttm
        }
      }
  `,
        variables: data
    }
    return makeRequest(query)
}

GraphqlMutations.closeBoxOfficeTerminal = (data) => {
    const query = {
        operationName: 'closeBoxOfficeTerminal',
        query: `mutation closeBoxOfficeTerminal($boxoffice_terminal_id: Int!, $closed_by: Int, $closing_amount: numeric) {
        update_boxoffice_terminals_by_pk(pk_columns: {boxoffice_terminal_id: $boxoffice_terminal_id}, _set: {closed_by: $closed_by, closing_amount: $closing_amount, status: "closed"}) {
          boxoffice_terminal_id
          closing_amount
          created_at
          opened_by
          opening_amount
          org_id
          status
          updated_dttm
        }
      }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlMutations.updateHardCardStatus = (data) => {
    const query = {
        operationName: 'updateHardCardStatus',
        query: `mutation updateHardCardStatus($hard_card_id: Int, $status: String) {
      update_hard_cards(where: {hard_card_id: {_eq: $hard_card_id}}, _set: {status: $status}) {
        affected_rows
      }
    }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlMutations.updateTeamMainContact = (data) => {
    const query = {
        operationName: 'update_team_main_contact',
        query: `mutation update_team_main_contact($team_user_id: Int, $team_id: Int, $user_id: Int, $main_contact: Boolean) {
      update_team_users(where: {team_user_id: {_eq: $team_user_id}, team_id: {_eq: $team_id}, user_id: {_eq: $user_id}}, _set: {main_contact: $main_contact}) {
        affected_rows
      }
    }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlMutations.updateOrgAccountOwnerUser = (data) => {
    const query = {
        operationName: 'update_org_account_owner_user',
        query: `mutation update_org_account_owner_user($team_id: Int, $org_account_owner: Int) {
      update_teams(where: {team_id: {_eq: $team_id}}, _set: {org_account_owner: $org_account_owner}) {
        affected_rows
      }
    }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlMutations.updateTeamAccesses = (data) => {
    const query = {
        operationName: 'update_teams',
        query: `mutation update_teams($team_id: Int, $guest_ticket_access: Boolean, $crew_access: Boolean, $multi_crew_admins: Boolean) {
      update_teams(where: {team_id: {_eq: $team_id}}, _set: {guest_ticket_access: $guest_ticket_access, crew_access: $crew_access, multi_crew_admins: $multi_crew_admins}) {
        affected_rows
      }
    }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlMutations.updateTeamUrls = (data) => {
    const query = {
        operationName: 'update_teams',
        query: `mutation update_teams($team_id: Int, $team_website: String, $team_facebook_url: String, $team_instagram_url: String, $team_youtube_url: String, $team_twitter_url: String) {
      update_teams(where: {team_id: {_eq: $team_id}}, _set: {team_website: $team_website, team_facebook_url: $team_facebook_url, team_instagram_url: $team_instagram_url, team_youtube_url: $team_youtube_url, team_twitter_url: $team_twitter_url}) {
        affected_rows
      }
    }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlMutations.updateTeamAdrs = (data) => {
    const query = {
        operationName: 'update_teams_adrs',
        query: `mutation update_teams_adrs($team_id: Int, $address_id: Int, $address_1: String, $address_2: String, $city: String, $postal_code: String, $state_province: String, $phone: String) {
      update_addresses(where: {team_id: {_eq: $team_id}, _and: {address_id: {_eq: $address_id}}}, _set: {address_1: $address_1, address_2: $address_2, city: $city, postal_code: $postal_code, state_province: $state_province, phone: $phone}) {
        affected_rows
      }
    }`,
        variables: data
    }
    return makeRequest(query)
}

// Insert Logo and Brand Guide for team detail page
GraphqlMutations.insertLogoAndBrandGuide = (data) => {
    const query = {
        operationName: 'insert_logo_and_brand_guide',
        query: `mutation insert_logo_and_brand_guide($user_id: Int, $team_id: Int, $file_display_name: String, $file_description: String, $url: String, $org_id: Int = ${Vue.prototype.$org.org_id}, $uploaded_by: Int) {
      insert_files(objects: {user_id: $user_id, team_id: $team_id, file_display_name: $file_display_name, file_description: $file_description, url: $url, org_id: $org_id, uploaded_by: $uploaded_by}) {
        affected_rows
      }
    }`,
        variables: data
    }
    return makeRequest(query)
}

// Update Logo and Brand Guide for team detail page
GraphqlMutations.updateLogoAndBrandGuide = (data) => {
    const query = {
        operationName: 'update_logo_and_brand_guide',
        query: `mutation update_logo_and_brand_guide($file_id: Int, $user_id: Int, $team_id: Int, $file_display_name: String, $file_description: String, $url: String, $updated_dttm: timestamp, $uploaded_by: Int) {
      update_files(where: {file_id: {_eq: $file_id}, _and: {team_id: {_eq: $team_id}}}, _set: {user_id: $user_id, team_id: $team_id, file_display_name: $file_display_name, file_description: $file_description, url: $url, updated_dttm: $updated_dttm, uploaded_by: $uploaded_by}) {
        affected_rows
      }
    }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlMutations.updateOrderItemProduct = (data) => {
    const query = {
        operationName: 'updateOrderItemProduct',
        query: `mutation updateOrderItemProduct($order_items_id: Int, $event_product_id: Int, $product_id: Int, $user: Int) {
      update_order_items(where: {order_items_id: {_eq: $order_items_id}}, _set: {event_product_id: $event_product_id, product_id: $product_id, modified_by: $user}) {
        affected_rows
        returning {
          order_items_id
        }
      }
    }
    `,
        variables: data
    }
    return makeRequest(query)
}

GraphqlMutations.createSeatsIOOrderItem = (data) => {
    const query = {
        operationName: 'insert_seatsio_order_items',
        query: `mutation insert_seatsio_order_items($order_items_id: Int, $sio_seat_id: String,  $status: String, $holdToken: String) {
      insert_seatsio_order_items(objects: {order_items_id: $order_items_id, sio_seat_id: $sio_seat_id, status: $status, holdToken: $holdToken}) {
        affected_rows
        returning {
            sio_oi_id
        }
      }
    }
    `,
        variables: data
    }
    return makeRequest(query)
}

GraphqlMutations.updateSeatsIOStatus = (data) => {
    const query = {
        operationName: 'update_seatsio_order_items',
        query: `mutation update_seatsio_order_items($holdToken: String, $status: String, $seatsio_payload: jsonb) {
      update_seatsio_order_items(_set: {status: $status, seatsio_payload: $seatsio_payload}, where: {holdToken: {_eq: $holdToken}}) {
          affected_rows
      }
  }
    `,
        variables: data
    }
    return makeRequest(query)
}

GraphqlMutations.updateSeatsIOOrderItemsId = (data) => {
    const query = {
        operationName: 'updateSeatsIOOrderItemsId',
        query: `mutation updateSeatsIOOrderItemsId($sio_oi_id: Int, $order_items_id: Int) {
      update_seatsio_order_items(where: {sio_oi_id: {_eq: $sio_oi_id}}, _set: {order_items_id: $order_items_id}) {
        affected_rows
        returning {
          sio_oi_id
        }
      }
    }
    `,
        variables: data
    }
    return makeRequest(query)
}

GraphqlMutations.updateSeatsIOByOrderItemsId = (data) => {
    const query = {
        operationName: 'updateSeatsIOByOrderItemsId',
        query: `mutation updateSeatsIOByOrderItemsId($sio_seat_id: String, $order_items_id: Int, $holdToken: String, $seatsio_payload: jsonb, $status: String) {
      update_seatsio_order_items(where: {order_items_id: {_eq: $order_items_id}}, _set: {sio_seat_id: $sio_seat_id, holdToken: $holdToken, seatsio_payload: $seatsio_payload, status: $status}) {
        affected_rows
      }
    }
    `,
        variables: data
    }
    return makeRequest(query)
}

GraphqlMutations.updateSeatsIOOrderItemsId = (data) => {
    const query = {
        operationName: 'updateSeatsIOOrderItemsId',
        query: `mutation updateSeatsIOOrderItemsId($sio_oi_id: Int, $order_items_id: Int) {
      update_seatsio_order_items(where: {sio_oi_id: {_eq: $sio_oi_id}}, _set: {order_items_id: $order_items_id}) {
        affected_rows
        returning {
          sio_oi_id
        }
      }
    }
    `,
        variables: data
    }
    return makeRequest(query)
}

GraphqlMutations.createCartSession = (data) => {
    const query = {
        operationName: 'createCartSession',
        query: `mutation createCartSession($payload: jsonb, $user_id: Int) {
      session : insert_cart_sessions_one(object: {payload: $payload, user_id: $user_id}, on_conflict: {constraint: cart_sessions_pkey, update_columns: payload}) {
        id
        created_at
      }
    }
    `,
        variables: data
    }
    return makeRequest(query)
}

GraphqlMutations.deleteCartSession = (data) => {
    const query = {
        operationName: 'deleteCartSession',
        query: `mutation deleteCartSession($id: uuid!) {
          delete_cart_sessions_by_pk(id: $id) {
            id
          }
        }
    `,
        variables: data
    }
    return makeRequest(query)
}

GraphqlMutations.updateCartSession = (data) => {
    const query = {
        operationName: 'updateCartSession',
        query: `mutation updateCartSession($id: uuid!, $payload: jsonb) {
          update_cart_sessions_by_pk(pk_columns: {id: $id}, _set: {payload: $payload}) {
            id
            updated_at
            created_at
          }
        }
    `,
        variables: data
    }
    return makeRequest(query)
}

GraphqlMutations.updateEventProductsGrid = (productInfo) => {
    const query = {
        operationName: 'update_event_products_grid',
        query: `mutation update_event_products_grid($quantity: Int, $max_quantity: Int, $event_products_id: Int, $product_id: Int, $status: String, $price: numeric, $guest_tickets_price: numeric, $boxoffice_price: numeric, $display_start_date: date, $display_end_date: date, $access_start: date, $access_end: date, $short_product_description: String, $product_description: String, $override_short_description: String, $override_description: String, $display_order: Int, $variant_display_name: String, $max_per_order: Int, $channels: jsonb, $override_product_name: String, $is_admission: Boolean, $is_media: Boolean, $transferable: Boolean, $checkin_info: String, $delivery_methods: jsonb, $max_per_team: Int, $event_product_transferable: Boolean) {
        update_event_products(where: {event_products_id: {_eq: $event_products_id}}, _set: {quantity: $quantity, max_quantity: $max_quantity, status: $status, price: $price, guest_tickets_price: $guest_tickets_price, boxoffice_price: $boxoffice_price, display_start_date: $display_start_date, display_end_date: $display_end_date, access_start: $access_start, access_end: $access_end, override_short_description: $override_short_description, override_description: $override_description, display_order: $display_order, variant_display_name: $variant_display_name, max_per_order: $max_per_order, channels: $channels, override_product_name: $override_product_name, checkin_info: $checkin_info, delivery_methods: $delivery_methods, max_per_team: $max_per_team, transferable: $event_product_transferable}) {
          affected_rows
        }
        update_products(where: {product_id: {_eq: $product_id}}, _set: {short_product_description: $short_product_description, product_description: $product_description, is_admission: $is_admission, is_media: $is_media, transferable: $transferable}) {
          affected_rows
        }
    }`,
        variables: productInfo
    }
    return makeRequest(query)
}

GraphqlMutations.updateVehicleNumberByID = (data) => {
    const query = {
        operationName: 'updateVehicleNumberByID',
        query: `mutation updateVehicleNumberByID($id: Int!, $vehicle_id: Int = null, $status: String, $claimed_by: Int, $assigned_by: Int = null, $notes: String = null) {
        update_vehicle_numbers_by_pk(pk_columns: {id: $id}, _set: {claimed_by: $claimed_by, assigned_by: $assigned_by, status: $status, vehicle_id: $vehicle_id, notes: $notes}) {
          id
        }
      }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlMutations.update_task_status = (taskInfo) => {
    const query = {
        operationName: 'update_task_status',
        query: `mutation update_task_status($task_id: Int, $status: String) {
        update_tasks(where: {task_id: {_eq: $task_id}}, _set: {status: $status}) {
          affected_rows
        }
      }`,
        variables: taskInfo
    }
    return makeRequest(query)
}

GraphqlMutations.updateTaskSubmissionData = (taskInfo) => {
    const query = {
        operationName: 'updateTaskSubmissionData',
        query: `mutation updateTaskSubmissionData($task_id: Int!, $submitted_by: Int, $submission_payload: jsonb, $user_id: Int, $status: String) {
          update_tasks_by_pk(pk_columns: {task_id: $task_id}, _set: {submitted_by: $submitted_by, submission_payload: $submission_payload, user_id: $user_id,  status: $status}) {
            task_id
          }
        }
        `,
        variables: taskInfo
    }
    return makeRequest(query)
}

GraphqlMutations.updateOrgTaskGrid = (taskInfo) => {
    const query = {
        operationName: 'updateOrgTaskGrid',
        query: `mutation updateOrgTaskGrid($task_id: Int!, $status: String, $auto_approve: Boolean, $restrict_to_user: Boolean, $due_dttm: timestamptz) {
          update_tasks_by_pk(pk_columns: {task_id: $task_id}, _set: {status: $status, auto_approve: $auto_approve, restrict_to_user: $restrict_to_user,  due_dttm: $due_dttm}) {
            task_id
          }
        }
        `,
        variables: taskInfo
    }
    return makeRequest(query)
}

GraphqlMutations.insertFormSubmission = (formInfo) => {
    const query = {
        operationName: 'insertFormSubmission',
        query: `mutation insertFormSubmission($user_id: Int, $team_id: Int = null, $org_id: Int, $status: String, $form_id: Int, $submission_data: jsonb, $form_schema: jsonb, $event_product_id: Int = null, $order_item_id: Int = null, $task_id: Int = null, $assigned_user_id: Int = null) {
          insert_form_submissions_one(object: {form_id: $form_id, org_id: $org_id, submission_data: $submission_data, status: $status, user_id: $user_id, team_id: $team_id, form_schema: $form_schema, event_product_id: $event_product_id, order_item_id: $order_item_id, task_id: $task_id, assigned_user_id: $assigned_user_id}) {
            submission_data
            id
          }
        }
        `,
        variables: formInfo
    }
    return makeRequest(query)
}

GraphqlMutations.updateFormSubmission = (formInfo) => {
    const query = {
        operationName: 'updateFormSubmission',
        query: `mutation updateFormSubmission($form_submission_id: Int!, $updated_at: timestamptz, $status: String, $submission_data: jsonb = "", $form_schema: jsonb = "") {
            update_form_submissions_by_pk(pk_columns: {id: $form_submission_id}, _set: {updated_at: $updated_at, status: $status, submission_data: $submission_data, form_schema: $form_schema}) {
              id
              updated_at
            }
          }
        `,
        variables: formInfo
    }
    return makeRequest(query)
}

GraphqlMutations.insertTask = (taskInfo) => {
    const query = {
        operationName: 'insertTask',
        query: `mutation insertTask($status: String, $user_id: Int, $team_id: Int = null, $org_id: Int = ${Vue.prototype.$org.org_id}, $due_dttm: timestamptz, $type: String, $form_id: String, $platform: String, $restrict_to_user: Boolean = false, $auto_approve: Boolean = false, $is_tech: Boolean = false, $is_media: Boolean = false, $name: String) {
          insert_tasks_one(object: {form_id: $form_id, org_id: $org_id, user_id: $user_id, name: $name, team_id: $team_id, status: $status, due_dttm: $due_dttm, type: $type, platform: $platform, restrict_to_user: $restrict_to_user, auto_approve: $auto_approve, is_tech: $is_tech, is_media: $is_media}) {
            task_id
          }
        }
        `,
        variables: taskInfo
    }
    return makeRequest(query)
}

GraphqlMutations.createErrorLogRecord = (data) => {
    const query = {
        operationName: 'createErrorLogRecord',
        query: `mutation createErrorLogRecord($table: String, $message: String, $event: String, $data: jsonb) {
        insert_event_error_log(objects: {data: $data, event: $event, message: $message, table: $table}) {
          returning {
            id
          }
        }
      }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlMutations.updateUserName = (data) => {
    const query = {
        operationName: 'updateUserName',
        query: `mutation updateUserName($user_id: Int!, $first_name: String, $full_name: String, $last_name: String) {
          user: update_users_by_pk(pk_columns: {user_id: $user_id}, _set: {first_name: $first_name, full_name: $full_name, last_name: $last_name}) {
            user_id
          }
        }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlMutations.updateCellPhone = (data) => {
    const query = {
        operationName: 'updateCellPhone',
        query: `mutation updateCellPhone($user_id: Int!, $cell_phone: String) {
          user: update_users_by_pk(pk_columns: {user_id: $user_id}, _set: {cell_phone: $cell_phone}) {
            user_id
          }
        }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlMutations.updateUserAddress = (data) => {
    const query = {
        operationName: 'updateUserAddress',
        query: `mutation updateUserAddress($user_id: Int!, $address_1: String, $address_2: String, $city: String, $country: String, $postal_code: String, $state_province: String) {
          user: update_users_by_pk(pk_columns: {user_id: $user_id}, _set: {address_1: $address_1, address_2: $address_2, country: $country, city: $city, postal_code: $postal_code, state_province: $state_province}) {
            user_id
            address_1
            address_2
            country
            postal_code
            state_province
          }
        }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlMutations.update_form_submissions_status = (statusInfo) => {
    const query = {
        operationName: 'update_form_submissions_status',
        query: `mutation update_form_submissions_status($id: Int, $status: String) {
        update_form_submissions(where: {id: {_eq: $id}}, _set: {status: $status}) {
          affected_rows
        }
      }`,
        variables: statusInfo

    }
    return makeRequest(query)
}

GraphqlMutations.updateEventSchedule = (data) => {
    const query = {
        operationName: 'updateEventSchedule',
        query: `mutation updateEventSchedule($schedule_modal: String, $event_id: Int) {
            update_events(where: {event_id: {_eq: $event_id}}, _set: {schedule_modal: $schedule_modal}) {
              affected_rows
            }
          }`,
        variables: data

    }
    return makeRequest(query)
}

GraphqlMutations.updateEventInfo = (data) => {
    const query = {
        operationName: 'updateEventInfo',
        query: `mutation updateEventInfo($info_modal: String, $event_id: Int) {
            update_events(where: {event_id: {_eq: $event_id}}, _set: {info_modal: $info_modal}) {
              affected_rows
            }
          }`,
        variables: data

    }
    return makeRequest(query)
}

GraphqlMutations.updateOrderFulfillmentStatusByOrderItemID = (item) => {
    const query = {
        operationName: 'updateOrderFulfillmentStatusByOrderItemID',
        query: `mutation updateOrderFulfillmentStatusByOrderItemID($order_items_id: Int, $status: String) {
      update_order_fulfillment_items(where: {order_items_id: {_eq: $order_items_id}}, _set: {status: $status}) {
        affected_rows
      }
    }`,
        variables: item
    }
    return makeRequest(query)
}

GraphqlMutations.updateOfficialTeamName = (data) => {
    const query = {
        operationName: 'updateOfficialTeamName',
        query: `mutation updateOfficialTeamName($team_id: Int!, $official_team_name: String) {
          team: update_teams_by_pk(pk_columns: {team_id: $team_id}, _set: {official_team_name: $official_team_name}) {
            team_name
            official_team_name
            team_id
          }
        }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlMutations.logMotorSportsDataEditedBy = (data) => {
    const query = {
        operationName: 'logMotorSportsDataEditedBy',
        query: `mutation logMotorSportsDataEditedBy($motorsports_data_id: Int!, $last_edited_by: Int) {
          motorsports_data: update_order_items_motorsports_data_by_pk(pk_columns: {motorsports_data_id: $motorsports_data_id}, _set: {last_edited_by: $last_edited_by}) {
            motorsports_data_id
          }
        }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlMutations.updateMotorSportsDataRunClass = (data) => {
    const query = {
        operationName: 'updateMotorSportsDataRunClass',
        query: `mutation updateMotorSportsDataRunClass($motorsports_data_id: Int!, $run_class: String, $last_edited_by: Int) {
          motorsports_data: update_order_items_motorsports_data_by_pk(pk_columns: {motorsports_data_id: $motorsports_data_id}, _set: {last_edited_by: $last_edited_by, run_class: $run_class}) {
            motorsports_data_id
          }
        }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlMutations.updateMotorSportsDataStatus = (data) => {
    const query = {
        operationName: 'updateMotorSportsDataStatus',
        query: `mutation updateMotorSportsDataStatus($motorsports_data_id: Int!, $motorsports_status: String, $last_edited_by: Int) {
          motorsports_data: update_order_items_motorsports_data_by_pk(pk_columns: {motorsports_data_id: $motorsports_data_id}, _set: {last_edited_by: $last_edited_by, motorsports_status: $motorsports_status}) {
            motorsports_data_id
          }
        }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlMutations.insertOwnedTransponder = (data) => {
    const query = {
        operationName: 'insertOwnedTransponder',
        query: `mutation insertOwnedTransponder($number: String, $user_id: Int, $type: String) {
          transponder: insert_transponders_one(object: {number: $number, user_id: $user_id, type: $type}) {
            transponder_id
          }
        }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlMutations.updateMotorSportsDataTransponder = (data) => {
    const query = {
        operationName: 'updateMotorSportsDataTransponder',
        query: `mutation updateMotorSportsDataTransponder($motorsports_data_id: Int!, $transponder_id: Int, $last_edited_by: Int) {
        motorsports_data: update_order_items_motorsports_data_by_pk(pk_columns: {motorsports_data_id: $motorsports_data_id}, _set: {last_edited_by: $last_edited_by, transponder_id: $transponder_id}) {
          motorsports_data_id
        }
      }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlMutations.updateMotorSportsDataGarageID = (data) => {
    const query = {
        operationName: 'updateMotorSportsDataGarageID',
        query: `mutation updateMotorSportsDataGarageID($motorsports_data_id: Int!, $garage_id: Int, $last_edited_by: Int) {
        motorsports_data: update_order_items_motorsports_data_by_pk(pk_columns: {motorsports_data_id: $motorsports_data_id}, _set: {last_edited_by: $last_edited_by, garage_id: $garage_id}) {
          motorsports_data_id
        }
      }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlMutations.updateMotorSportsDataVehicleNumber = (data) => {
    const query = {
        operationName: 'updateMotorSportsDataVehicleNumber',
        query: `mutation updateMotorSportsDataVehicleNumber($motorsports_data_id: Int!, $vehicle_number: Int, $last_edited_by: Int) {
        motorsports_data: update_order_items_motorsports_data_by_pk(pk_columns: {motorsports_data_id: $motorsports_data_id}, _set: {last_edited_by: $last_edited_by, vehicle_number: $vehicle_number}) {
          motorsports_data_id
        }
      }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlMutations.updateTransponderRentalStatus = (data) => {
    const query = {
        operationName: 'updateTransponderRentalStatus',
        query: `mutation updateTransponderRentalStatus($transponder_id: Int!, $rented_by: Int, $last_rented_event: Int, $inventory_status: String, $updated_by: Int) {
          update_transponders_by_pk(pk_columns: {transponder_id: $transponder_id}, _set: {rented_by: $rented_by, last_rented_event: $last_rented_event, inventory_status: $inventory_status, updated_by: $updated_by}) {
            transponder_id
          }
        }
        `,
        variables: data
    }
    return makeRequest(query)
}

GraphqlMutations.updateUserFields = (data) => {
    const { user_id, ...fieldsToUpdate } = data

    const setClause = Object.entries(fieldsToUpdate).reduce((acc, [field, value]) => {
        acc += `${field}: $${field}, `
        return acc
    }, '')

    const variableTypes = Object.entries(fieldsToUpdate).reduce((acc, [field, value]) => {
        const fieldType = typeof value
        const gqlType = getGraphQLType(fieldType, value) // Function to convert JavaScript type to GraphQL type
        acc += `$${field}: ${gqlType}, `
        return acc
    }, '')

    const query = {
        operationName: 'updateUserFields',
        query: `mutation updateUserFields($user_id: Int!, ${variableTypes}) {
        user: update_users_by_pk(pk_columns: {user_id: $user_id}, _set: {${setClause}}) {
          user_id
        }
      }`,
        variables: data
    }

    return makeRequest(query)
}

GraphqlMutations.updateMotorSportsDataFields = (data) => {
    const { motorsports_data_id, ...fieldsToUpdate } = data

    const setClause = Object.entries(fieldsToUpdate).reduce((acc, [field, value]) => {
        acc += `${field}: $${field}, `
        return acc
    }, '')

    const variableTypes = Object.entries(fieldsToUpdate).reduce((acc, [field, value]) => {
        const fieldType = typeof value
        const gqlType = getGraphQLType(fieldType, value) // Function to convert JavaScript type to GraphQL type
        acc += `$${field}: ${gqlType}, `
        return acc
    }, '')

    const query = {

        operationName: 'updateMotorSportsDataFields',
        query: `mutation updateMotorSportsDataFields($motorsports_data_id: Int!, ${variableTypes}) {
        motorsports_data: update_order_items_motorsports_data_by_pk(pk_columns: {motorsports_data_id: $motorsports_data_id}, _set: {${setClause}}) {
          motorsports_data_id
        }
      }`,
        variables: data
    }

    return makeRequest(query)
}

GraphqlMutations.updateTransponderFields = (data) => {
    const { transponder_id, ...fieldsToUpdate } = data

    const setClause = Object.entries(fieldsToUpdate).reduce((acc, [field, value]) => {
        acc += `${field}: $${field}, `
        return acc
    }, '')

    const variableTypes = Object.entries(fieldsToUpdate).reduce((acc, [field, value]) => {
        const fieldType = typeof value
        const gqlType = getGraphQLType(fieldType, value) // Function to convert JavaScript type to GraphQL type
        acc += `$${field}: ${gqlType}, `
        return acc
    }, '')

    const query = {

        operationName: 'updateTransponderFields',
        query: `mutation updateTransponderFields($transponder_id: Int!, ${variableTypes}) {
          transponder: update_transponders_by_pk(pk_columns: {transponder_id: $transponder_id}, _set: {${setClause}}) {
            transponder_id
          }
        }`,
        variables: data
    }

    return makeRequest(query)
}

GraphqlMutations.updateVehicleNumbersFields = (data) => {
    const { id, ...fieldsToUpdate } = data

    const setClause = Object.entries(fieldsToUpdate).reduce((acc, [field, value]) => {
        acc += `${field}: $${field}, `
        return acc
    }, '')

    const variableTypes = Object.entries(fieldsToUpdate).reduce((acc, [field, value]) => {
        const fieldType = typeof value
        const gqlType = getGraphQLType(fieldType, value) // Function to convert JavaScript type to GraphQL type
        acc += `$${field}: ${gqlType}, `
        return acc
    }, '')

    const query = {
        operationName: 'updateVehicleNumbersFields',
        query: `mutation updateVehicleNumbersFields($id: Int!, ${variableTypes}) {
          vehicle_numbers: update_vehicle_numbers_by_pk(pk_columns: {id: $id}, _set: {${setClause}}) {
            id
          }
        }`,
        variables: data
    }

    return makeRequest(query)
}

GraphqlMutations.updateOrderItemFields = (data) => {
    const { order_items_id, ...fieldsToUpdate } = data

    const setClause = Object.entries(fieldsToUpdate).reduce((acc, [field, value]) => {
        acc += `${field}: $${field}, `
        return acc
    }, '')

    const variableTypes = Object.entries(fieldsToUpdate).reduce((acc, [field, value]) => {
        const fieldType = typeof value
        const gqlType = getGraphQLType(fieldType, value) // Function to convert JavaScript type to GraphQL type
        acc += `$${field}: ${gqlType}, `
        return acc
    }, '')

    const query = {
        operationName: 'updateOrderItemFields',
        query: `mutation updateOrderItemFields($order_items_id: Int!, ${variableTypes}) {
        order_item: update_order_items_by_pk(pk_columns: {order_items_id: $order_items_id}, _set: {${setClause}}) {
          order_items_id
        }
      }`,
        variables: data
    }

    return makeRequest(query)
}

GraphqlMutations.updateRecordFields = (tableName, primaryKey, data) => {
    const {
        [primaryKey]: pkValue, ...fieldsToUpdate
    } = data

    const setClause = Object.entries(fieldsToUpdate).reduce((acc, [field, value]) => {
        acc += `${field}: $${field}, `
        return acc
    }, '')

    const variableTypes = Object.entries(fieldsToUpdate).reduce((acc, [field, value]) => {
        const fieldType = typeof value
        const gqlType = getGraphQLType(fieldType, value) // Function to convert JavaScript type to GraphQL type
        acc += `$${field}: ${gqlType}, `
        return acc
    }, '')

    const query = {
        operationName: `update${tableName}Fields`,
        query: `mutation update${tableName}Fields($${primaryKey}: Int!, ${variableTypes}) {
      record: update_${tableName}_by_pk(pk_columns: {${primaryKey}: $${primaryKey}}, _set: {${setClause}}) {
        ${primaryKey}
      }
    }`,
        variables: {
            [primaryKey]: pkValue,
            ...fieldsToUpdate
        }
    }

    return makeRequest(query)
}

GraphqlMutations.insertGarageRecord = (data) => {
    const query = {
        operationName: 'insertGarageRecord',
        query: `mutation insertGarageRecord($object: garage_insert_input = {}) {
        garage: insert_garage_one(object: $object) {
          garage_id
        }
      }
      `,
        variables: data
    }
    return makeRequest(query)
}

GraphqlMutations.insertProduct = (data) => {
    const query = {
        operationName: 'insertProduct',
        query: `mutation insertProduct($object: products_insert_input = {}) {
        product: insert_products_one(object: $object) {
          product_id
        }
      }
      `,
        variables: data
    }
    return makeRequest(query)
}

GraphqlMutations.updateRecordFieldsTyped = (tableName, primaryKey, data) => {
  const { [primaryKey]: pkValue, ...fieldsToUpdate } = data

  const setClause = Object.entries(fieldsToUpdate).reduce((acc, [field, { value, type }]) => {
    acc += `${field}: $${field}, `
    return acc
  }, '')

  const variableTypes = Object.entries(fieldsToUpdate).reduce((acc, [field, { type }]) => {
    acc += `$${field}: ${type}, `
    return acc
  }, '')

  // make an object with fieldsToUpdate keys and get the value from the object
  const values = Object.entries(fieldsToUpdate).reduce((acc, [field, { value }]) => { acc[field] = value; return acc }, {})

  const query = {
    operationName: `update${tableName}Fields`,
    query: `mutation update${tableName}Fields($${primaryKey}: Int!, ${variableTypes}) {
      record: update_${tableName}_by_pk(pk_columns: {${primaryKey}: $${primaryKey}}, _set: {${setClause}}) {
        ${primaryKey}
      }
    }`,
    variables: {
      [primaryKey]: pkValue.value,
      ...values
    }
  }

  return makeRequest(query)
}

GraphqlMutations.insertEventProduct = (data) => {
    const query = {
        operationName: 'insertEventProduct',
        query: `mutation insertEventProduct($object: event_products_insert_input = {}) {
        event_product: insert_event_products_one(object: $object) {
          event_products_id
        }
      }
      `,
        variables: data
    }
    return makeRequest(query)
}

GraphqlMutations.updateMotorSportsDataTransferFix = (data) => {
    const query = {
        operationName: 'updateMotorSportsDataTransferFix',
        query: `mutation updateMotorSportsDataTransferFix($motorsports_data_id: Int!, $team_id: Int,$user_id: Int, $transponder_id: Int, $is_timing_export: Boolean = true, $run_class: String, $run_sub_class: String, $vehicle_number: Int, $driver_profile_id: Int, $garage_id: Int,  $last_edited_by: Int, $display_official_team_name: Boolean = false) {
          update_order_items_motorsports_data_by_pk(pk_columns: {motorsports_data_id: $motorsports_data_id}, _set: {motorsports_data_id: $motorsports_data_id, team_id: $team_id, user_id: $user_id, transponder_id: $transponder_id, is_timing_export: $is_timing_export, run_class: $run_class, run_sub_class: $run_sub_class, driver_profile_id: $driver_profile_id, garage_id: $garage_id, last_edited_by: $last_edited_by, vehicle_number: $vehicle_number, display_official_team_name: $display_official_team_name}) {
            motorsports_data_id
          }
        }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlMutations.insertFulfillmentItem = (data) => {
    const query = {
        operationName: 'insertFulfillmentItem',
        query: `mutation insertFulfillmentItem($object: product_items_insert_input = {}) {
        fulfillment_item: insert_product_items_one(object: $object) {
          product_items_id
        }
      }
      `,
        variables: data
    }
    return makeRequest(query)
}

GraphqlMutations.deleteFulfillmentItem = (item) => {
    const query = {
        operationName: 'deleteFulfillmentItem',
        query: `mutation deleteFulfillmentItem($product_items_id: Int!) {
          delete_product_items_by_pk(product_items_id: $product_items_id) {
            product_items_id
          }
        }`,
        variables: item
    }
    return makeRequest(query)
}

GraphqlMutations.insertEvent = (data) => {
    const query = {
        operationName: 'insertEvent',
        query: `mutation insertEvent($object: events_insert_input = {}) {
        event: insert_events_one(object: $object) {
          event_id
        }
      }
      `,
        variables: data
    }
    return makeRequest(query)
}

GraphqlMutations.debitCredit = (data) => {
    const query = {
      operationName: 'debitCredit',
      query: `mutation debitCredit($amount: numeric, $credit_id: Int, $order_number: Int, $source_channel: String, $user_id: Int) {
        insert_credit_transactions(objects: {type: "debit", amount: $amount, credit_id: $credit_id, order_number: $order_number, source_channel: $source_channel, user_id: $user_id}) {
          returning {
            amount
            credit_id
            credit_transaction_id
            currency
            notes
            order_item_id
            order_number
            source_channel
            transaction_dttm
            type
            user_id
          }
        }
      }`,
      variables: data
    }
    return makeRequest(query)
}

GraphqlMutations.insertGroupUser = (data) => {
    const query = {
        operationName: 'insertGroupUser',
        query: `mutation insertGroupUser($object: group_users_insert_input = {}) {
          insert_group_users_one(object: $object) {
            group_user_id
          }
        }`,
        variables: data
    }
    return makeRequest(query)
}

GraphqlMutations.update_form_submission_approval_notes = (taskInfo) => {
    const query = {
        operationName: 'update_form_submission_approval_notes',
        query: `mutation update_form_submission_approval_notes($id: Int!, $note: String!) {
          update_form_submissions_by_pk(pk_columns: {id: $id}, _set: {approval_notes: $note}) {
            id
          }
        }`,
        variables: taskInfo
    }
    return makeRequest(query)
}

export default GraphqlMutations