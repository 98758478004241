/* eslint-disable no-unexpected-multiline */
/* Core */
import Vue from 'vue'
import Buefy from 'buefy'
import PrimeVue from 'primevue/config'
import Toast from 'primevue/toast'
import ToastService from 'primevue/toastservice'
import ConfirmationService from 'primevue/confirmationservice'
import Ripple from 'primevue/ripple'
import Tooltip from 'primevue/tooltip'
import Chip from 'primevue/chip'
import Button from 'primevue/button'
import InputText from 'primevue/inputtext'
import VueHaptic from 'vue-haptic'
import { supabase } from '@/supabase'
import Vueform from '@vueform/vueform'
import vueformConfig from './../vueform.config'

/* Router & Store */
import store from './store'
import 'primeflex/primeflex.css'
import '@/scss/_theme-prime-vue-tork.css'
import 'primevue/resources/primevue.min.css'
import 'primeicons/primeicons.css'
import Flag from 'vue-flagpack'

/* utils */
// eslint-disable-next-line no-unused-vars
import filters from './utils/filters'
import axios from 'axios'
import VueGtm from '@gtm-support/vue2-gtm'
import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'
import VueTelInput from 'vue-tel-input'
import 'vue-tel-input/dist/vue-tel-input.css'
import VeeValidate from 'vee-validate'
import GraphqlQueries from '@/graphql/queries/graphqlQueries'
import update from './mixins/update'
/* Import library for aws rum. */

import { AwsRum } from 'aws-rum-web'

/* Service Worker */
import './registerServiceWorker'

import { Amplify, Auth, Hub } from 'aws-amplify'
import awsmobile from './aws-exportsTemp'
import {
    applyPolyfills,
    defineCustomElements
} from '@aws-amplify/ui-components/loader'
import router from './router'

Vue.config.productionTip = false
Vue.use(Buefy, {
    defaultIconPack: 'fal',
    defaultSnackbarDuration: 5000
})
Vue.component('ValidationProvider', VeeValidate.ValidationProvider)
Vue.component('ValidationObserver', VeeValidate.ValidationObserver)
Vue.use(VeeValidate)
Vue.directive('ripple', Ripple)
Vue.component('Toast', Toast)
Vue.component('Chip', Chip)
Vue.component('Button', Button)
Vue.component('InputText', InputText)

Vue.use(PrimeVue, { ripple: true })
Vue.use(ToastService)
Vue.use(ConfirmationService)
Vue.use(Flag, {
    name: 'Flag'
})

Vue.directive('tooltip', Tooltip)

Amplify.configure(awsmobile)
applyPolyfills().then(() => {
    defineCustomElements(window)
})

async function getOrgBySubdomain() {
    const domain = window.location.host
    const parts = domain.split('.')
    if (parts[0].includes('staging-')) {
        parts[0] = parts[0].split('-')[1]
    }
    if (parts.length > 1) {
        Vue.prototype.$subdomain = (parts[0] !== 'staging' ? parts[0] : 'app')
        try {
            const request = await axios({
                url: `https://api.torkhub.com/api/rest/v1/org/${Vue.prototype.$subdomain}`,
                method: 'get',
                headers: {
                    'x-hasura-admin-secret': process.env.VUE_APP_HASURA_KEY
                }
            }).then(async result => {
                if (result.data.errors) {
                    console.error(result.data.errors[0].message)
                    location.href = process.env.NODE_ENV !== 'development' ? 'https://app.torkhub.com' : 'http://app.torkhub.local:8080'
                    return false
                } else {
                    if (result.data.organization.length) {
                        return await result.data.organization[0]
                    }
                    location.href = process.env.NODE_ENV !== 'development' ? 'https://app.torkhub.com' : 'http://app.torkhub.local:8080'
                    return false
                }
            })
            Vue.prototype.$org = await request
        } catch (error) {
            console.log(error)
            location.href = process.env.NODE_ENV !== 'development' ? 'https://app.torkhub.com' : 'http://app.torkhub.local:8080'
        }
    }
}

(async() => {
    await getOrgBySubdomain()
    Vue.prototype.$subdomain = Vue.prototype.$org.subdomain ? Vue.prototype.$org.subdomain : 'app'
    document.documentElement.classList.add(Vue.prototype.$subdomain)
})()

if (process.env.NODE_ENV !== 'development') {
    const domain = window.location.host
    const parts = domain.split('.')
    Sentry.init({
        Vue,
        environment: parts[0].includes('staging-') ? 'staging' : process.env.NODE_ENV,
        release: 'TorkHub ' + require('../package.json').version,
        dsn: 'https://1f76b56836da4f0198e93a38dd575783@o1083292.ingest.sentry.io/6092915',
        integrations: [
            new Integrations.BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(router)
            })
        ],
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        trackComponents: true
    })

    Vue.prototype.$Sentry = Sentry
}
// Adding aws RUM
try {
    const config = {
        sessionSampleRate: 1,
        guestRoleArn: 'arn:aws:iam::093446538796:role/RUM-Monitor-us-east-1-093446538796-9732352232661-Unauth',
        identityPoolId: 'us-east-1:ff0ef92a-4902-47a8-a40d-54c86b793283',
        endpoint: 'https://dataplane.rum.us-east-1.amazonaws.com',
        telemetries: ['performance', 'errors', 'http'],
        allowCookies: true,
        enableXRay: true
    }

    const APPLICATION_ID = '876422ee-8cbb-44ec-b10b-09da7b8d31e3'
    const APPLICATION_VERSION = '1.0.0'
    const APPLICATION_REGION = 'us-east-1'

    const awsRum = new AwsRum(
        APPLICATION_ID,
        APPLICATION_VERSION,
        APPLICATION_REGION,
        config
    )
    Vue.use(awsRum)
} catch (error) {
    // Ignore errors thrown during CloudWatch RUM web client initialization
}

// // GTM
Vue.use(VueGtm, {
    id: 'GTM-K6ZJHG9', //  GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
    defer: false,
    compatibility: false,
    enabled: (process.env.NODE_ENV !== 'development'),
    debug: false,
    loadScript: true,
    vueRouter: router,
    trackOnNextTick: false
})

Vue.use(VueHaptic, {
    // Required. vue-haptic does not provide
    // any out-of-the-box patterns
    patterns: {
        success: [10, 100, 30],
        failure: [10, 50, 10, 50, 50, 100, 10],
        long: 200,
        default: 10
    }
})

Vue.use(Vueform, vueformConfig)

Hub.listen('auth', ({ payload: { event, data } }) => {
        if (event === 'signOut') {
            if (router.history.current.name !== 'tickets') {
                router.push({ path: '/auth' })
            }
        }

        Hub.listen(/.*/, data => {
            // console.log('Listening for all messages: ', data.payload.data)
        })
    })
    // activate addons
Vue.use(VueTelInput)

Vue.use(ToastService)
Vue.component('Toast', Toast)

axios.defaults.params = {}

const AppLightDark = () =>
    import ('./AppLightDark')

/* Default title tag */
const defaultDocumentTitle = 'TorkHub'

/* Detect mobile layout */
store.dispatch('layoutMobileToggle')

window.onresize = () => {
    store.dispatch('layoutMobileToggle')
}

/* Collapse mobile aside menu on route change & set title */
router.afterEach(to => {
    store.commit('asideMobileStateToggle', false)
    store.commit('overlayToggle', false)
    store.commit('asideActiveForcedKeyToggle', null)
    if (to.meta && to.meta.title) {
        document.title = `${to.meta.title} — ${defaultDocumentTitle}`
    } else {
        document.title = defaultDocumentTitle
    }
})

// Global Vars
Vue.prototype.$impersonate = null

Vue.prototype.$app_version = require('../package.json').version
    // custom route overrides
router.beforeEach(async(to, from, next) => {
    if (!Vue.prototype.$org) {
        await getOrgBySubdomain()
    }

    if (to.fullPath.substring(0, 2) === '/#') {
        const path = to.fullPath.substring(2)
        next(path)
    }
    // if (to.fullPath === '/') {
    //     next({ name: 'profile' })
    // }
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
    const requiresSpecialSauce = to.matched.some(record => record.meta.requiresSpecialSauce)
    const requiresOrgAdmin = to.matched.some(record => record.meta.requiresOrgAdmin)
    const requiresTeamAdmin = to.matched.some(record => record.meta.requiresTeamAdmin)
    const orgAccess = to.meta.orgAccess
    const roleAccess = to.meta.roleAccess
    const notConsumer = to.matched.some(record => record.meta.notConsumer)
    const { data: { user } } = await supabase.auth.getUser()
    const loggedInUser = user
    let loggedIn = loggedInUser || false
        // let permissions = null
    let isOrgUser = false
    let isConsumer = false
    let isTeamUser = false
    let orgPermissions = []

    // await Auth.currentAuthenticatedUser().then(async(user) => {

    let torkuser = null
    if (loggedIn && loggedInUser) {
        // Vue.prototype.$cognitoGroups = (user.signInUserSession.accessToken.payload['cognito:groups'] ? user.signInUserSession.accessToken.payload['cognito:groups'] : null)
        try {
            // impersonation
            if (loggedInUser) {
                torkuser = await GraphqlQueries.getLoggedInUserInfo({ email: (store.state.impersonate ? store.state.user.email : loggedInUser.email) })
                torkuser = torkuser.data.users[0]
            }
        } catch (error) {
            torkuser = null
            loggedIn = false
                // return false
        }
        if (torkuser) {
            store.commit('user', torkuser)
            Vue.prototype.$loggedInUser = torkuser
                // if (torkuser.status !== 'confirmed') {
                //   await GraphqlMutations.updateUserStatus({ user_id: torkuser.user_id, status: 'confirmed' })
                // }
            stonlyTrack('identify', `${torkuser.user_id}`)
                // let userOrg = await GraphqlQueries.getUserOrg({ user_id: torkuser.user_id })
            let userOrgs = torkuser.org_users
            userOrgs = userOrgs.length ? userOrgs : null
            Vue.prototype.$userOrgs = JSON.parse(JSON.stringify(userOrgs))
            if (userOrgs) {
                orgPermissions = [...new Set(userOrgs.filter(org => org.org_id === Vue.prototype.$org.org_id).map((orgUser) => orgUser.role_id))]
            }
            const userTeams = (torkuser.team_users.length ? torkuser.team_users : null)
            Vue.prototype.$userTeams = JSON.parse(JSON.stringify(userTeams))
            const userMediaOutlets = (torkuser.media_outlet_users.length ? torkuser.media_outlet_users : null)
            Vue.prototype.$userMediaOutlets = userMediaOutlets
            store.dispatch('fetchUser')
            if (userOrgs || Vue.prototype.$userOrgs) {
                isOrgUser = true
            }

            if (userTeams || userMediaOutlets) {
                isTeamUser = true
            }

            if (!userTeams && !userMediaOutlets && !userOrgs) {
                Vue.prototype.$isConsumer = true
                isConsumer = true
            }
        } else {
            console.log('no user loaded')
            store.dispatch('fetchUser')
                // return false
        }
    }

    //     return true
    // })
    // .catch(e => {
    //     return false
    // })

    if (requiresAuth && !loggedIn) {
        // await store.dispatch('clearCaches')
        const queryObj = { from: to.path }
        if (to.query.email || to.query.e) {
            queryObj.email = to.query.email ? to.query.email : to.query.e
        }
        next({ name: 'Supa Auth', query: queryObj })
    }

    if (orgAccess && orgAccess.length) {
        if (!orgAccess.includes(Vue.prototype.$org.org_id)) {
            next({ name: 'NotFound' })
        }
    }

    if (notConsumer && isConsumer) {
        next({ name: 'NotFound' })
    }

    if (requiresSpecialSauce && !store.state.user.special_sauce) {
        next({ name: 'NotFound' })
    }

    if (requiresOrgAdmin) {
        if (!loggedIn) {
            next({ name: 'Supa Auth', query: { from: to.path } })
        } else if (!isOrgUser) {
            next({ name: 'NotFound' })
        }
        if (roleAccess && roleAccess.length) {
            if (!orgPermissions || !orgPermissions.some((role) => to.meta.roleAccess.includes(role))) {
                next({ name: 'NotFound' })
            }
        }
    }
    if (requiresTeamAdmin) {
        // restrict based on role
        const isTeamAdminOnThisTeam = Vue.prototype.$loggedInUser.team_users.find(
            (team) => team.team_id === Number(to.params.team_id) && [3].includes(team.role_id)
        )
        if (!isTeamAdminOnThisTeam) { next({ name: 'NotFound' }) }
    }

    if (to.path === '/') {
        const nextObj = {
            name: 'profile'
        }
        if (to.query) {
            nextObj.query = to.query
        }
        next(nextObj)
    }

    switch (to.name) {
        case 'auth':
            if (loggedIn) {
                // send to profile if login is requested and already auth'd
                next('/profile')
            } else {
                next()
            }
            break
        default:
            next()
    }
})

new Vue({
    router,
    store,
    mixins: [update],
    mode: 'history',
    // Default style example
    // render (h) {
    //   return h(AppDefault)
    // },

    // Light mode example
    // render (h) {
    //   store.commit('appStyle', 'light-dark') // ... or change default in src/store/index.js
    //   return h(AppLightDark)
    // },

    // Dark mode example
    // render (h) {
    //   store.commit('appStyle', 'light-dark') // ... or change default in src/store/index.js
    //   store.commit('darkModeToggle', true) // ... or change default in src/store/index.js
    //   return h(AppLightDark)
    // },

    // Demo render() with default & light-dark styles
    render(h) {
        const queryString = window.location.search

        if (queryString.indexOf('iframe-preview-mode') > -1) {
            store.commit('iframePreviewMode', true)
        }
        store.commit('appStyle', 'light-dark')

        return h(AppLightDark)
    }
}).$mount('#app')