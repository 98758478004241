<template>
  <div class="is-user-avatar">
    <img v-if="newUserAvatar" :src="newUserAvatar" />
    <b-icon v-else icon="user-circle"></b-icon>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  export default {
    name: 'UserAvatar',
    props: {
      avatar: {
        type: String,
        default: null
      }
    },
    computed: {
      ...mapState(['userAvatar', 'user']),
      newUserAvatar() {
        if (this.avatar) {
          return this.avatar
        }

        if (this.userAvatar) {
          return this.userAvatar
        }

        if (this.user) {
          if (this.user.full_name) {
            return `https://api.dicebear.com/7.x/initials/svg?seed=${this.user.full_name}`
          }
        }
        return 'https://cdn.torkhub.com/th/thuser.svg'
      }
    }
  }
</script>
<style scoped>
  .navbar-item img {
    height: 48px;
    width: 48px;
    object-fit: cover;
  }
</style>
