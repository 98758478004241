<template>
  <div id="app" :class="{ 'container has-boxed-layout': isLayoutBoxed }">
    <div
      v-if="impersonate"
      id="systemNotice"
      class="container-fluid has-background-warning has-text-white has-text-weight-bold has-text-right px-3 py-1"
    >
      <p>Impersonation Mode Active. Refresh To Exit.</p>
    </div>
    <nav-bar />
    <aside-menu
      :menu="this.generatedMenu && this.generatedMenu.length ? this.sortedNav : consumer"
      :menu-bottom="menuBottom"
      @menu-click="menuClick"
      :icon="menuMainIcon"
      :class="{ 'has-secondary': !!menuSecondary }"
    />
    <!-- <aside-menu
    v-if="generatedTeamMenu && generatedTeamMenu.length"
      :menu="generatedTeamMenu"
      :menu-bottom="menuBottom"
      @menu-click="menuClick"
      :icon="menuMainIcon"
      :class="{ 'has-secondary': !!menuSecondary }"
    /> -->
    <!-- <aside-menu
      :menu="consumer"
      :menu-bottom="menuBottom"
      @menu-click="menuClick"
      :icon="menuMainIcon"
      :class="{ 'has-secondary': !!menuSecondary }"
    /> -->
    <aside-menu
      v-if="menuSecondary"
      :menu="menuSecondary"
      :is-secondary="true"
      :label="menuSecondaryLabel"
      :icon="menuSecondaryIcon"
      @menu-click="menuClick"
      @close="menuSecondaryClose"
    />
    <!-- <keep-alive exclude="WillCallTicketDetails, NewOrder, Profile"> -->
    <router-view></router-view>
    <!-- </keep-alive> -->
    <aside-right />
    <footer-bar />
    <overlay @overlay-click="overlayClick" />
  </div>
</template>

<script>
  // @ is an alias to /src
  import { mapState } from 'vuex'
  import NavBar from '@/components/NavBar'
  import AsideMenu from '@/components/AsideMenu'
  import FooterBar from '@/components/FooterBar'
  import Overlay from '@/components/Overlay'
  import AsideRight from '@/components/AsideRight'
  // import ConfigBox from '@/components/ConfigBox'
  // import { Auth } from 'aws-amplify'
  import update from '@/mixins/update'
  import GraphqlQueries from '@/graphql/queries/graphqlQueries'

  export default {
    name: 'MainLayout',
    components: {
      // ConfigBox,
      AsideRight,
      Overlay,
      FooterBar,
      AsideMenu,
      NavBar
    },
    data() {
      return {
        menuSecondary: null,
        menuSecondaryLabel: null,
        menuSecondaryIcon: null,
        menuMainIcon: 'fa-torkhub-icon',
        paths: this.$route.matched,
        navStuffs: null,
        org_id: this.$org.org_id,
        userOrgMatch: null,
        generatedMenu: null,
        generatedOrgMenu: null,
        generatedTeamMenu: null,
        generatedConsumerMenu: null,
        userRoles: null,
        teamRoles: null,
        teamTypes: null,
        teams: null,
        teamSubTypes: null,
        userOrgs: null,
        registration: null,
        updateExists: false,
        fullNav: [
          'Team',
          [
            {
              to: '/team/dashboard',
              label: 'Dashboard',
              icon: 'tachometer-alt-fast'
            },
            {
              to: '/team/registration',
              label: 'Registration',
              icon: 'newspaper'
            },
            {
              to: '/team/crew',
              label: 'Crew',
              icon: 'users'
            },
            {
              to: '/team/profiles',
              label: 'Profiles',
              icon: 'user-circle'
            },
            {
              to: '/team/todo',
              label: 'To-Do Items',
              icon: 'clipboard-list-check'
            },
            {
              to: '/team/guests',
              label: 'Guest Tickets',
              icon: 'users-class'
            }
          ],
          'Will Call',
          [
            {
              to: '/willcall/tickets',
              label: 'Tickets',
              icon: 'ticket-alt',
              updateMark: true
            }
          ],
          'Hard Cards',
          [
            {
              to: '/hardcards/all',
              label: 'Hard Cards',
              icon: 'id-badge',
              updateMark: true
            }
          ],
          'Seating Charts',
          [
            {
              to: '/manager/seatselector',
              label: 'Seat Selector',
              icon: 'chair'
            },
            {
              to: '/manager/chartmanager',
              label: 'Chart Manager',
              icon: 'project-diagram'
            },
            {
              to: '/manager/chartdesigner',
              label: 'Chart Designer',
              icon: 'pencil-ruler'
            },
            {
              to: '/manager/eventmanager',
              label: 'Event Manager',
              icon: 'tasks-alt'
            }
          ]
        ],
        teamRegPostPurchase: [
          'Team',
          [
            {
              to: '/team/dashboard',
              label: 'Dashboard',
              icon: 'tachometer-alt-fast'
            },
            {
              to: '/team/application',
              label: 'Application',
              icon: 'newspaper'
            }
            // {
            //   to: '/team/crew',
            //   label: 'Crew',
            //   icon: 'users'
            // },
            // {
            //   to: '/team/profiles',
            //   label: 'Profiles',
            //   icon: 'user-circle'
            // }
            // {
            //   to: '/team/todo',
            //   label: 'To-Do Items',
            //   icon: 'clipboard-list-check'
            // },
            // {
            //   to: '/team/guests',
            //   label: 'Guest Tickets',
            //   icon: 'users-class'
            // }
          ]
        ],
        orgAdminView: [
          [
            {
              label: 'Dashboards',
              icon: 'tachometer-alt-fast',
              menu: [
                {
                  to: '/org/dashboard',
                  label: 'Dashboard 1'
                },
                {
                  to: '/org/dashboard',
                  label: 'Dashboard 2'
                }
              ]
            },
            {
              to: '/org/approvals',
              label: 'Registrations',
              icon: 'clipboard-list-check'
            },
            {
              label: 'Next Section',
              icon: 'tachometer-alt-fast',
              menu: [
                {
                  to: '/org/dashboard',
                  label: 'Dashboard 1'
                },
                {
                  to: '/org/dashboard',
                  label: 'Dashboard 2'
                }
              ]
            }
          ]
        ],
        teamAdminNav: [
          'Team',
          [
            {
              to: '/team/dashboard',
              label: 'Dashboard',
              icon: 'tachometer-alt-fast'
            }
          ],
          'My Torkhub',
          [
            {
              to: '/my/events',
              label: 'My Events',
              icon: 'clipboard-list-check'
            },
            {
              to: '/profile',
              label: 'My Profile',
              icon: 'user-circle'
            },
            {
              to: '/my/tasks',
              label: 'My Tasks',
              icon: 'tasks'
            }
          ]
        ],
        mediaNav: [
          'Media',
          [
            {
              to: '/media/dashboard',
              label: 'Dashboard',
              icon: 'tachometer-alt-fast'
            }
          ],
          'My Torkhub',
          [
            {
              to: '/my/events',
              label: 'My Events',
              icon: 'clipboard-list-check'
            },
            {
              to: '/profile',
              label: 'My Profile',
              icon: 'user-circle'
            },
            {
              to: '/my/tasks',
              label: 'My Tasks',
              icon: 'tasks'
            }
          ]
        ],
        lockout: [
          'My Torkhub',
          [
            {
              to: '/my/events',
              label: 'My Events',
              icon: 'clipboard-list-check'
            },
            {
              to: '/profile',
              label: 'My Profile',
              icon: 'user-circle'
            },
            {
              to: '/my/tasks',
              label: 'My Tasks',
              icon: 'tasks'
            }
          ]
        ],
        consumer: [
          'My Torkhub',
          [
            {
              to: '/my/events',
              label: 'My Events',
              icon: 'clipboard-list-check'
            },
            {
              to: '/profile',
              label: 'My Profile',
              icon: 'user-circle'
            },
            {
              to: '/my/tasks',
              label: 'My Tasks',
              icon: 'tasks'
            }
          ]
        ]
      }
    },
    mixins: [update],
    computed: {
      sortedNav() {
        return [...this.generatedMenu, ...this.consumer]
      },
      menu() {
        return this.generatedMenu
      },
      menuBottom() {
        return [
          // {
          //   icon: 'copyright',
          //   label: '2021 TorkBrain',
          //   state: 'light'
          // },
          // {
          //   action: 'logout',
          //   icon: 'sign-out-alt',
          //   label: 'Log out',
          //   state: 'dark'
          // }
        ]
      },
      ...mapState([
        'isOverlayVisible',
        'isLayoutBoxed',
        'isLayoutAsideHidden',
        'isLayoutMobile',
        'applicantStatus',
        'purchaseStatus',
        'user',
        'permissions',
        'userTeam',
        'userOrg',
        'cart',
        'impersonate'
      ])
    },
    async created() {
      document.addEventListener('swUpdated', this.updateAvailable, { once: true })
      const navStuffs = await GraphqlQueries.getNavByOrg({ org_id: this.org_id })
      this.navStuffs = navStuffs.data.nav_sidebar
      this.generatedNav()
    },
    mounted() {
      document.documentElement.classList.remove('has-spinner-active')
      document.documentElement.classList.add('backend')
    },
    beforeDestroy() {
      document.documentElement.classList.remove('backend')
    },
    methods: {
      async generatedNav() {
        this.generatedOrgMenu = []
        this.generatedTeamMenu = []
        this.generatedConsumerMenu = []
        this.teams = [...new Set(this.user.team_users.map((item) => item.team))]

        this.teams = this.teams
          .filter((team) => {
            return team.org_id === this.org_id
          })
          .filter((value, index, self) => index === self.findIndex((t) => t.team_id === value.team_id))
        this.userOrgs = this.user.org_users.filter((user) => {
          return user.organization.org_id === this.org_id
        })
        // loop org and build org menus
        for (var oi = 0; oi < this.userOrgs.length; oi++) {
          for (const navObj of this.navStuffs) {
            if (navObj.is_org) {
              await this.buildOrgMenuItem(navObj, oi)
            }
          }
        }
        // loop team teams to build the team navs
        for (var i = 0; i < this.teams.length; i++) {
          this.generatedTeamMenu[i] = []
          this.teamRoles = [...new Set(this.user.team_users.filter((user) => user.team_id === this.teams[i].team_id))]
          this.teamRoles = [...new Set(this.teamRoles.map((user) => user.role_id))]
          this.teamTypes = [...new Set(this.teams.map((team) => team.type))]
          this.teamSubTypes = this.teams.length && this.teams[i].sub_type ? [...new Set(this.teams[i].sub_type.map((subtype) => subtype))] : []

          for (const navObj of this.navStuffs) {
            const TeamGuestTicketAccess = this.teams[i].guest_ticket_access
            const TeamCrewAccess = this.teams[i].crew_access
            const UserGuestTicketAccess = this.user.team_users.find((user) => user.team_id === this.teams[i].team_id && user.purchase_tickets)
            // process Org Menu
            if (!navObj.is_org) {
              // team navs
              // check team type
              if (navObj.visible_team_type ? [...new Set(this.teamTypes.filter((type) => navObj.visible_team_type.includes(type)))] : null) {
                // check team sub type
                if (navObj.visible_team_sub_type ? navObj.visible_team_sub_type.some((item) => this.teamSubTypes.includes(item)) : null) {
                  // check user role
                  if (navObj.visible_to_role ? [...new Set(this.teamRoles.filter((role) => navObj.visible_to_role.includes(role)))].length : null) {
                    if (!navObj.check_guest_tickets_access && !navObj.check_crew_page_access) {
                      await this.buildTeamMenuItem(navObj, i)
                    } else if (navObj.check_guest_tickets_access && UserGuestTicketAccess && TeamGuestTicketAccess) {
                      if (!navObj.check_crew_page_access) {
                        await this.buildTeamMenuItem(navObj, i)
                      } else if (navObj.check_crew_page_access && TeamCrewAccess) {
                        await this.buildTeamMenuItem(navObj, i)
                      }
                    } else if (navObj.check_crew_page_access && TeamCrewAccess) {
                      if (!navObj.check_guest_tickets_access) {
                        await this.buildTeamMenuItem(navObj, i)
                      } else if (navObj.check_guest_tickets_access && UserGuestTicketAccess && TeamGuestTicketAccess) {
                        await this.buildTeamMenuItem(navObj, i)
                      }
                    }
                  }
                }
              }
            }
          }
          // this.generatedMenu = this.generatedMenu.sort((a, b) => (a[0].display_order > b[0].display_order ? 1 : -1))
          this.generatedTeamMenu[i].unshift('TEAM - ' + this.teams[i].team_name)
        }
        this.sortNav()
      },
      async buildOrgMenuItem(navObj, oi) {
        if (navObj.is_parent) {
          if (this.userOrgs.length && navObj.visible_to_role.includes(this.userOrgs[oi].role_id)) {
            const menu = {
              label: navObj.nav_label,
              pack: navObj.icon_pack ? navObj.icon_pack : null,
              icon: navObj.has_icon ? navObj.icon : null,
              menu: navObj.is_parent ? [] : null,
              display_order: navObj.display_order || null
            }
            if (navObj.is_parent) {
              const navChildren = this.navStuffs.filter((nav) => {
                return nav.nest_under === navObj.nav_sidebar_id
              })
              for (const child of navChildren) {
                if (child.visible_to_role.includes(this.userOrgs[oi].role_id)) {
                  if (child.route_params) {
                    for (const property in child.route_params) {
                      switch (property) {
                        case 'org_id':
                          child.route_params[property] = this.userOrgs.org_id
                          break
                      }
                    }
                  }
                  menu.menu.push({
                    to: child.show_coming_soon
                      ? '/coming/soon'
                      : child.route_params
                      ? { name: child.route_name, params: JSON.parse(JSON.stringify(child.route_params)) }
                      : child.url,
                    label: child.nav_label,
                    display_order: child.display_order || null,
                    icon: child.has_icon ? child.icon : null
                  })
                }
              }
            }
            // loadedMenu.push([menu])
            this.generatedOrgMenu.push([menu])
          }
        } else {
          if (navObj.nest_under < 1 && this.userOrgs.length && navObj.visible_to_role.includes(this.userOrgs[oi].role_id)) {
            const menu = {
              label: navObj.nav_label,
              pack: navObj.icon_pack ? navObj.icon_pack : null,
              icon: navObj.has_icon ? navObj.icon : null,
              menu: navObj.is_parent ? [] : null,
              display_order: navObj.display_order || null,
              to: navObj.show_coming_soon
                ? '/coming/soon'
                : navObj.route_params
                ? { name: navObj.route_name, params: JSON.parse(JSON.stringify(navObj.route_params)) }
                : navObj.url
            }
            // loadedMenu.push([menu])
            this.generatedOrgMenu.push([menu])
          }
        }
        // this.generatedOrgMenu = this.generatedOrgMenu.sort((a, b) => (a[0].display_order > b[0].display_order ? 1 : -1))
      },
      async buildTeamMenuItem(navObj, i) {
        // if (navObj.is_parent) {
        const menu = {
          label: navObj.nav_label,
          pack: navObj.icon_pack ? navObj.icon_pack : null,
          icon: navObj.has_icon ? navObj.icon : null,
          menu: navObj.is_parent ? [] : null,
          display_order: navObj.display_order || null
        }
        if (navObj.is_parent) {
          const navChildren = this.navStuffs.filter((nav) => {
            return nav.nest_under === navObj.nav_sidebar_id
          })
          for (const child of navChildren) {
            if (child.visible_to_role ? [...new Set(this.teamRoles.filter((role) => child.visible_to_role.includes(role)))].length : null) {
              if (child.route_params) {
                for (const property in child.route_params) {
                  switch (property) {
                    case 'team_id':
                      child.route_params[property] = this.teams[i].team_id
                      break
                  }
                }
              }
              menu.menu.push({
                to: child.show_coming_soon
                  ? '/coming/soon'
                  : child.route_params
                  ? { name: child.route_name, params: JSON.parse(JSON.stringify(child.route_params)) }
                  : child.url,
                label: child.nav_label,
                display_order: child.display_order || null,
                icon: child.has_icon ? child.icon : null
              })
            }
          }

          // loadedMenu.push([menu])
          this.generatedTeamMenu[i].push([menu])
        } else {
          if (navObj.nest_under < 1) {
            if (navObj.route_params) {
              for (const property in navObj.route_params) {
                switch (property) {
                  case 'team_id':
                    navObj.route_params[property] = this.teams[i].team_id
                    break
                }
              }
            }
            const menu = {
              label: navObj.nav_label,
              pack: navObj.icon_pack ? navObj.icon_pack : null,
              icon: navObj.has_icon ? navObj.icon : null,
              menu: navObj.is_parent ? [] : null,
              display_order: navObj.display_order || null,
              to: navObj.show_coming_soon
                ? '/coming/soon'
                : navObj.route_params
                ? { name: navObj.route_name, params: JSON.parse(JSON.stringify(navObj.route_params)) }
                : navObj.url
            }

            // loadedMenu.push([menu])
            this.generatedTeamMenu[i].push([menu])
          }
        }
        //  }
        //  this.generatedTeamMenu = this.generatedTeamMenu.sort((a, b) => (a[0].display_order > b[0].display_order ? 1 : -1))
      },
      sortNav() {
        this.generatedOrgMenu = this.generatedOrgMenu.sort((a, b) => (a[0].display_order > b[0].display_order ? 1 : -1))
        this.generatedTeamMenu = this.generatedTeamMenu.sort((a, b) => (a[0].display_order > b[0].display_order ? 1 : -1))
        if (this.generatedOrgMenu.length) {
          this.generatedOrgMenu.unshift('ORG')
        }
        //  if (this.generatedTeamMenu.length) { this.generatedTeamMenu.unshift('TEAM') }
        this.generatedMenu = [...this.generatedOrgMenu]
        this.generatedTeamMenu.forEach((team) => {
          if (team.length > 1) {
            this.generatedMenu.push(...team)
          }
        })
      },
      updateAvailable(event) {
        this.registration = event.detail
        this.updateExists = true
      },
      refreshApp() {
        this.updateExists = false
        // Make sure we only send a 'skip waiting' message if the SW is waiting
        if (!this.registration || !this.registration.waiting) return
        // Send message to SW to skip the waiting and activate the new SW
        this.registration.waiting.postMessage({ type: 'SKIP_WAITING' })
      },
      async menuClick(item) {
        if (item.menuSecondary) {
          this.menuSecondary = item.menuSecondary
          this.menuSecondaryLabel = item.menuSecondaryLabel ? item.menuSecondaryLabel : null
          this.menuSecondaryIcon = item.menuSecondaryIcon ? item.menuSecondaryIcon : null

          this.$store.commit('asideActiveForcedKeyToggle', item)
          this.$store.commit('overlayToggle', true)
        } else if (item.action && item.action === 'logout') {
          await this.$store.dispatch('logout')
        }
      },
      menuSecondaryClose() {
        this.menuSecondary = this.menuSecondaryLabel = this.menuSecondaryIcon = null
        this.$store.commit('asideActiveForcedKeyToggle', null)

        if (!this.isLayoutAsideHidden) {
          this.$store.commit('overlayToggle', false)
        }
      },
      overlayClick() {
        if (this.menuSecondary) {
          this.menuSecondaryClose()
        } else {
          this.$store.dispatch('asideCloseAll')
        }
      }
    },
    watch: {
      isLayoutMobile(newVal) {
        if (newVal) {
          this.$store.commit('layoutBoxedToggle', false)
        }
      },
      impersonate() {
        this.generatedNav()
      }
      // generatedOrgMenu () {
      //   // this.generatedOrgMenu = this.generatedOrgMenu.sort((a, b) => (a[0].display_order > b[0].display_order ? 1 : -1))
      //   this.generatedMenu = [...this.generatedOrgMenu, ...this.generatedTeamMenu]
      // },
      // generatedTeamMenu () {
      //   // this.generatedTeamMenu = this.generatedTeamMenu.sort((a, b) => (a[0].display_order > b[0].display_order ? 1 : -1))
      //   this.generatedMenu = [...this.generatedOrgMenu, ...this.generatedTeamMenu]
      // }
    }
  }
</script>
