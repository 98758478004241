/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    // "aws_cognito_identity_pool_id": "us-east-1_abe9SL4mv",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_abe9SL4mv",
    "aws_user_pools_web_client_id": "21kdjkkpom7hrmv3ho34ksuql7",
    "oauth": {},
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    API: {
        graphql_endpoint: 'https://4p4tgyblgzes3m3yqnmt7fqrqq.appsync-api.us-east-1.amazonaws.com/graphql',
        graphql_headers: async() => ({
            'x-api-key': 'da2-g5jkpa7tobeu7ofehrqskeqgrq',
        })
    }
};


export default awsmobile;