<template>
  <aside
    id="mainSideNav"
    v-show="isAsideVisible"
    class="aside is-placed-left"
    :class="{ 'is-expanded': isAsideExpanded || isSecondary, 'is-secondary': isSecondary, hovered: asideHovered }"
  >
    <aside-tools
      class="mb-4 has-background-primary has-text-white has-text-centered"
      :has-close="isSecondary"
      :expanded="isAsideExpanded"
      :icon="icon"
      @close="close"
    >
      <span v-if="!isSecondary && isAsideExpanded">
        <img class="nav-logo" src="@/assets/TorkHub-White.png" />
      </span>
      <span v-else-if="label">{{ label }}</span>
    </aside-tools>
    <div id="mainMenuExpand" class="is-clickable" @click.prevent="menuToggle"></div>
    <div ref="menuContainer" class="menu-container" @mouseenter="psUpdate">
      <div class="menu is-menu-main">
        <div class="menu-label">
          <Dropdown
            v-if="orgs && orgs.length"
            v-model="selectedOrg"
            :options="orgs"
            optionLabel="org_name"
            :placeholder="selectedOrg.org_name"
            class="p-button-sm"
          />
        </div>
        <template v-for="(menuGroup, index) in menu">
          <p class="menu-label" v-if="typeof menuGroup === 'string'" :key="index">{{ menuGroup }}</p>
          <aside-menu-list v-else :key="index" :icon="icon" :is-secondary="isSecondary" @menu-click="menuClick" :menu="menuGroup" />
        </template>
      </div>
    </div>
    <div v-if="isAsideExpanded" class="p-2">&copy; {{ new Date() | year }}, TorkBrain</div>
    <div class="p-2 is-size-7">v{{ $app_version }}</div>
    <div class="menu is-menu-bottom">
      <aside-menu-list :menu="menuBottom" @menu-click="menuClick" />
    </div>
    <Dialog id="orgSwitcherDialog" :visible.sync="showRedirectMessage" position="top">
      <template #header>
        <h3 class="has-text-weight-bold">Switching Portals</h3>
      </template>

      Are you sure you want to switch to the <span class="has-text-weight-bold">{{ selectedOrg.org_name }}</span> portal?

      <template #footer class="test">
        <Button
          label="No"
          icon="pi pi-times"
          class="p-button-text"
          @click="
            selectedOrg = $org
            showRedirectMessage = !showRedirectMessage
          "
        />
        <Button label="Yes" icon="pi pi-check" autofocus @click="redirect" />
      </template>
    </Dialog>
  </aside>
</template>

<script>
  import { mapState } from 'vuex'
  import PerfectScrollbar from 'perfect-scrollbar'
  import AsideTools from '@/components/AsideTools'
  import AsideMenuList from '@/components/AsideMenuList'
  import Dropdown from 'primevue/dropdown'
  import Dialog from 'primevue/dialog'

  export default {
    name: 'AsideMenu',
    components: { AsideTools, AsideMenuList, Dropdown, Dialog },
    props: {
      menu: {
        type: Array,
        default: () => []
      },
      menuBottom: {
        type: Array,
        default: () => []
      },
      isSecondary: {
        type: Boolean,
        default: false
      },
      label: {
        type: String,
        default: null
      },
      icon: {
        type: String,
        default: null
      }
    },
    data() {
      return {
        selectedOrg: this.$org,
        showRedirectMessage: false,
        asideHovered: false
      }
    },
    computed: {
      ...mapState(['isAsideVisible', 'isAsideExpanded', 'isLayoutMobile', 'isAsideMobileExpanded', 'user']),
      orgs() {
        if (!this.user) {
          return []
        }
        const teamUsers =
          this.user && this.user.team_users ? this.user.team_users.filter((user) => ['active'].includes(user.team.status.toLowerCase())) : []
        const group1 = this.user.org_users ? [...new Set(this.user.org_users.map((org) => org.organization))] : []
        const group2 = teamUsers ? [...new Set(teamUsers.map((user) => user.team.organization))] : []
        const grouped = [...group1, ...group2].filter((value, index, self) => index === self.findIndex((t) => t.org_id === value.org_id))

        return grouped.filter((org) => org.org_id !== this.$org.org_id)
      }
    },
    mounted() {
      this.ps = new PerfectScrollbar(this.$refs.menuContainer)
      const mainSideNav = document.getElementById('mainSideNav')

      // mainSideNav.addEventListener(
      //   'mouseenter',
      //   (event) => {
      //     if (!this.isAsideExpanded) {
      //       this.asideHovered = true
      //       this.$store.commit('asideStateToggle')
      //     }
      //   },
      //   false
      // )
      // mainSideNav.addEventListener(
      //   'mouseleave',
      //   (event) => {
      //     if (this.asideHovered) {
      //       this.$store.commit('asideStateToggle')
      //       this.asideHovered = false
      //     }
      //   },
      //   false
      // )
    },
    methods: {
      menuClick(item) {
        this.$emit('menu-click', item)
      },
      psUpdate() {
        if (this.ps) {
          this.ps.update()
        }
      },
      menuToggle() {
        if (this.isLayoutMobile) {
          this.$store.commit('asideMobileStateToggle')
        } else if (this.isLayoutAsideHidden) {
          this.$store.dispatch('asideVisibilityToggle')
        } else {
          this.$store.commit('asideStateToggle')
        }
      },
      close() {
        this.$emit('close')
      },
      redirect() {
        location.href = `https://${this.selectedOrg.subdomain}.torkhub.com`
      },
      async logout() {
        await this.$store.dispatch('logout')
      }
    },
    watch: {
      selectedOrg(newValue) {
        if (newValue.org_id !== this.$org.org_id) {
          this.showRedirectMessage = true
        }
      }
    }
  }
</script>
<style scoped>
  .nav-logo {
    max-width: 200px;
    padding-top: 2px;
  }

  #mainMenuExpand {
    z-index: 100;
    right: -20px;
    top: 54px;
    position: absolute;
    border-radius: 50% 50%;
    box-shadow: 6px 0px 7px -6px rgb(0 0 0 / 70%);
  }
</style>
