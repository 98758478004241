/* eslint-disable no-unused-vars */
/**
 * @name SessionHandlers
 *
 * @version 0.0.1
 *
 * @copyright 2021 TorkHub
 * @author AJ Schalund
 */
import Vue from 'vue'

import { Auth } from 'aws-amplify'

export default new class {
  async signOut () {
    try {
      await Auth.signOut()
    } catch (error) {
      console.log('error signing out: ', error)
    } finally {
      this.clearSessionData()
    }
  }

  // clear session data
  async clearSessionData () {
    try {
      localStorage.clear()
    } catch (error) {
      console.log('error clearing session data: ', error)
    }
  }
}()
