import Vue from 'vue'
import Vuex from 'vuex'
import { Auth } from 'aws-amplify'
import GraphqlQueries from '@/graphql/queries/graphqlQueries'
import GraphqlMutations from '@/graphql/mutations/graphqlMutations'
import { supabase } from '@/supabase'
import router from '@/router'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        /* AppStyle (needed for demo render() in main.js) */
        appStyle: 'default',

        /* User */
        user: null,
        authorized: false,
        cognitoUser: null,
        torkUser: null,
        userName: null,
        userEmail: null,
        userAvatar: null,
        userAttributes: null,
        loginStatus: null,

        /* org */
        userOrg: null,
        orgPermissions: null,
        willCallOrders: [],
        fetchingWillCallOrders: false,

        /* team */
        userTeam: null,
        teams: null,

        /* cart */
        showShoppingCart: false,
        showConsumerShoppingCart: false,
        showGuestTicketsCart: false,
        cart: null,
        cartUserInfo: null,

        /* permissions */
        permissions: {
            isTeamUser: false,
            isOrgUser: false,
            isConsumer: true,
            isTorkAdmin: false
        },

        /* purchase status */
        purchaseStatus: null,

        /* applicant status */
        applicantStatus: null,

        /* NavBar */
        isNavBarVisible: true,
        navBarColor: null,

        /* FooterBar */
        isFooterBarVisible: true,

        /* Aside */
        isAsideVisible: true,
        isAsideExpanded: false,
        isAsideMobileExpanded: false,
        asideActiveForcedKey: null,
        isAsideRightVisible: false,
        isAsideRightActive: false,

        /* Updates */
        hasUpdates: false,

        /* Overlay */
        isOverlayVisible: false,

        /* Layout */
        isLayoutBoxed: false,
        isLayoutAsideHidden: false,
        isLayoutMobile: false,

        /* Dark mode (available with AppLightDark.vue only) */
        isDarkModeActive: false,

        /* Iframe */
        isIframePreviewMode: false,

        /* ConfigBox */
        isConfigBoxVisible: true,

        impersonate: false
    },
    mutations: {
        /* User */
        user(state, payload) {
            state.user = payload
            state.userAvatar = state.user ? state.user.headshot ? state.user.headshot : state.user.profile_photo ? state.user.profile_photo : null : null
        },
        userOrg(state, payload) {
            state.userOrg = payload
        },
        orgPermissions(state, payload) {
            state.orgPermissions = payload
        },
        userTeam(state, payload) {
            state.userTeam = payload
        },
        fetchingWillCallOrders(state, payload) {
            state.fetchingWillCallOrders = payload
        },
        willcall(state, payload) {
            state.willCallOrders[payload.eventID] = payload.results
        },
        teams(state, payload) {
            state.teams = payload
        },
        cognitoUser(state, payload) {
            state.cognitoUser = payload
        },
        confirm(state, showConfirm) {
            state.confirm = !!showConfirm
        },
        cart(state, payload) {
            state.cart = payload
        },
        showCart(state, payload) {
            state.showShoppingCart = payload
        },
        showGuestTicketsCart(state, payload) {
            state.showGuestTicketsCart = payload
        },
        showConsumerCart(state, payload) {
            state.showConsumerShoppingCart = payload
        },
        cartUserInfo(state, payload) {
            state.cartUserInfo = payload
        },
        addToCart(state, data) {
            (state.cart === null) ? (state.cart = [data]) : state.cart.push(data)
        },
        addToCartUserInfo(state, data) {
            (state.cartUserInfo === null) ? (state.cartUserInfo = [data]) : state.cartUserInfo.push(data)
        },
        /* A fit-them-all commit */
        basic(state, payload) {
            state[payload.key] = payload.value
        },

        /* AppStyle */
        appStyle(state, payload) {
            state.appStyle = payload
        },

        applicant(state, payload) {
            if (payload.status) {
                state.applicantStatus = payload.status
            }
        },

        purchase(state, payload) {
            if (payload.status) {
                state.purchaseStatus = payload.status
            }
        },

        /* Full Page mode */
        fullPage(state, payload) {
            state.isConfigBoxVisible = !payload
            state.isNavBarVisible = !payload
            state.isAsideVisible = !payload
            state.isFooterBarVisible = !payload
            state.isOverlayVisible = false
        },

        /* Aside Desktop Visibility */
        asideVisibilityToggle(state, payload) {
            state.isAsideVisible = payload
        },

        /* Aside Desktop State */
        asideStateToggle(state, payload = null) {
            const htmlAsideClassName = 'has-aside-expanded'
            const isExpand = payload !== null ? payload : !state.isAsideExpanded

            document.documentElement.classList[isExpand ? 'add' : 'remove'](htmlAsideClassName)

            state.isAsideExpanded = isExpand
        },

        /* Aside Mobile State */
        asideMobileStateToggle(state, payload = null) {
            const isShow = payload !== null ? payload : !state.isAsideMobileExpanded

            document.documentElement.classList[isShow ? 'add' : 'remove']('has-aside-mobile-expanded')

            state.isAsideVisible = true
            state.isAsideMobileExpanded = isShow
        },

        /* Aside Forced Active Key (when secondary submenu is open) */
        asideActiveForcedKeyToggle(state, payload) {
            state.asideActiveForcedKey = payload && payload.menuSecondaryKey ? payload.menuSecondaryKey : null
        },

        /* Aside Right */
        asideRightToggle(state, payload) {
            state.isAsideRightVisible = payload
            state.isAsideRightActive = payload
            state.hasUpdates = false
        },

        /* Overlay */
        overlayToggle(state, payload = null) {
            const setIsVisible = payload !== null ? payload : !state.isOverlayVisible

            if (!setIsVisible && state.isLayoutAsideHidden && (state.isAsideVisible || state.isAsideRightVisible)) {
                return
            }

            state.isOverlayVisible = setIsVisible

            document.documentElement.classList[setIsVisible ? 'add' : 'remove']('is-clipped')
        },

        /* Layouts */

        layoutBoxedToggle(state, payload = null) {
            const setIsBoxed = payload !== null ? payload : !state.isLayoutBoxed

            state.isLayoutAsideHidden = setIsBoxed
            state.isLayoutBoxed = setIsBoxed
            state.isAsideExpanded = setIsBoxed
            state.isAsideVisible = !setIsBoxed
            state.isAsideRightVisible = false
            state.isAsideRightActive = false

            document.documentElement.classList[setIsBoxed ? 'remove' : 'add']('has-aside-left', 'has-navbar-fixed-top')
            document.documentElement.classList[setIsBoxed ? 'add' : 'remove']('has-boxed-layout', 'has-aside-hidden-layout', 'has-aside-expanded')
        },

        layoutWideToggle(state, payload = null) {
            const setIsWide = payload !== null ? payload : !state.isLayoutBoxed

            state.isLayoutAsideHidden = setIsWide
            state.isAsideExpanded = setIsWide
            state.isAsideVisible = !setIsWide
            state.isAsideRightVisible = !setIsWide

            document.documentElement.classList[setIsWide ? 'remove' : 'add']('has-aside-left')
            document.documentElement.classList[setIsWide ? 'add' : 'remove']('has-aside-hidden-layout', 'has-aside-expanded')
        },

        layoutMobileToggle(state, payload) {
            state.isLayoutMobile = payload
        },

        /* Dark Mode */
        darkModeToggle(state, payload = null) {
            const setIsDark = payload !== null ? payload : !state.isDarkModeActive

            state.isDarkModeActive = setIsDark

            document.documentElement.classList[setIsDark ? 'add' : 'remove']('is-dark-mode-active')
        },

        /* Misc */

        setNavBarColor(state, payload) {
            state.navBarColor = payload
        },

        iframePreviewMode(state, payload) {
            state.isIframePreviewMode = payload
        }
    },
    actions: {
        asideCloseAll({ commit, state }) {
            commit('asideVisibilityToggle', false)
            commit('asideRightToggle', false)
            commit('overlayToggle', false)
        },
        asideVisibilityToggle({ commit, state }, payload = null) {
            const setIsVisible = payload !== null ? payload : !state.isAsideVisible

            commit('asideVisibilityToggle', setIsVisible)
            commit('overlayToggle', setIsVisible)
        },
        asideRightToggle({ commit, state }, payload = null) {
            const isShow = payload !== null ? payload : !state.isAsideRightVisible

            commit('asideRightToggle', isShow)

            if (state.isLayoutAsideHidden) {
                commit('overlayToggle', isShow)
            }

            if (!state.isLayoutAsideHidden) {
                document.documentElement.classList[isShow ? 'add' : 'remove']('has-aside-right')
            }
        },
        layoutMobileToggle({ commit, state }) {
            const isMobile = window.innerWidth < 1024

            commit('layoutMobileToggle', isMobile)

            document.documentElement.classList[isMobile && state.isIframePreviewMode ? 'add' : 'remove']('iframe-preview-mode')
        },
        toggleFullPage({ commit }, payload) {
            commit('layoutBoxedToggle', false)
            commit('fullPage', payload)

            document.documentElement.classList.remove('is-clipped')

            if (payload) {
                document.documentElement.classList.remove('has-aside-left', 'has-navbar-fixed-top')
            }
        },
        // Cognito Actions
        async login({ dispatch, state }, { email, password }) {
            try {
                await Auth.signIn(email, password)
                Vue.prototype.$buefy.snackbar.open({
                    message: 'Welcome, thanks for stopping by today.',
                    queue: false,
                    type: 'is-success'
                })
            } catch (err) {
                Vue.prototype.$buefy.snackbar.open({
                    message: 'Looks like there was a problem unlocking the car.  ' + err.message,
                    queue: false,
                    type: 'is-warning'
                })
                return false
            }
            await dispatch('fetchUser')
        },
        async signup({ commit, state }, user) {
            try {
                await Auth.signUp({
                        username: user.username,
                        password: user.password,
                        attributes: user.attributes,
                        validationData: []
                    })
                    // commit('confirm', true)
            } catch (err) {
                Vue.prototype.$buefy.snackbar.open({
                    message: 'Looks like there was a problem signing you up.  ' + err.message,
                    queue: false,
                    type: 'is-warning'
                })
                throw err
                    // commit('confirm', false)
            }
        },
        async confirm({ commit, dispatch, state }, { username, authCode }) {
            state.confirmError = ''
            try {
                await Auth.confirmSignUp(username, authCode)
                Vue.prototype.$buefy.snackbar.open({
                    message: 'Thank you for verifying your account. You may now login.',
                    queue: false,
                    type: 'is-success'
                })
            } catch (err) {
                Vue.prototype.$buefy.snackbar.open({
                    message: 'Looks like there was a problem checking your vin.  ' + err.message,
                    queue: false,
                    type: 'is-warning'
                })
                throw err
            }
            commit('confirm', false)
        },
        async authState({ commit, dispatch }, state) {
            if (state === 'signedIn') { await dispatch('fetchUser') } else { commit('user', null) }
        },
        async fetchUser({ commit, dispatch, state }) {
            try {
                const { data: { user } } = await supabase.auth.getUser()
                    // await supabase.auth.getUser()
                    //     // Auth.currentAuthenticatedUser()
                    //     .then(async data => {    
                    // commit('cognitoUser', user.attributes)
                    // impersonation
                if (user) {
                    let torkuser = await GraphqlQueries.getLoggedInUserInfo({ email: state.impersonate ? state.user.email : user.email })
                    torkuser = torkuser.data.users[0]
                    if (torkuser) {
                        commit('user', torkuser)
                        if (state.user.status !== 'confirmed') GraphqlMutations.updateUserStatus({ user_id: state.user.user_id, status: 'confirmed' })
                    } else {
                        console.log('fetch user not found lets create')
                        await dispatch('createTorkUser', user)
                    }
                    // let userOrg = await GraphqlQueries.getUserOrg({ user_id: state.user.user_id })
                    // userOrg = (userOrg.data.org_users.length ? userOrg.data.org_users[0].organization : null)
                    // commit('user', torkuser)
                    if (state.userOrg) {
                        state.permissions.isOrgUser = true
                    }
                    // check if team_user
                    // const userTeam = await GraphqlQueries.getTeamByUser({ user_id: state.user.user_id })
                    //     // userTeam = (userTeam.data.team_users.length ? userTeam.data.team_users[0] : null)
                    let userOrgs = torkuser.org_users // TODO: Double check this for multi Tenancy 
                    userOrgs = userOrgs.length ? userOrgs : null
                    if (userOrgs) {
                        commit('userOrg', userOrgs)
                        commit('orgPermissions', [...new Set(userOrgs.filter(org => org.org_id === Vue.prototype.$org.org_id).map((orgUser) => orgUser.role_id))])
                    }
                    if (torkuser.team_users.length) {
                        commit('userTeam', torkuser.team_users)
                    }
                    if (state.userOrg) {
                        state.permissions.isOrgUser = true
                    }
                    // commit('userTeam', userTeam)
                    if (state.userTeam && state.userTeam.active === 'active') {
                        state.permissions.isTeamUser = true
                    }

                    const teams = await GraphqlQueries.getTeamByUserNew({ user_id: state.user.user_id })
                    commit('teams', teams)

                    if (state.teams && state.teams.active === 'active') {
                        state.permissions.isTeamUser = true
                    }
                }
            } catch (error) {
                console.error(error)
                commit('user', null)
            }
        },
        async logout({ commit, dispatch }) {
            try {
                if (router.history.current.name !== 'tickets') {
                    router.push({ name: 'Supa Auth' })
                }
                const { error } = await supabase.auth.signOut()
                if (error) {
                    throw error(error)
                }
                Vue.prototype.$buefy.snackbar.open({
                    message: 'Thanks for stopping by.',
                    queue: false,
                    type: 'is-success'
                })
                commit('user', null)
                commit('cognitoUser', null)
            } catch (error) {
                console.error('error signing out: ', error)
            } finally {
                await dispatch('clearCaches')
            }
        },
        async createTorkUser({ commit }, user) {
            try {
                const userInfo = {
                    email: user.email.toLowerCase(),
                    cell_phone: (user.phone ? user.phone_number : user.user_metadata.cell_phone ? user.user_metadata.cell_phone : null),
                    first_name: (user.user_metadata.first_name ? user.user_metadata.first_name : null),
                    last_name: (user.user_metadata.last_name ? user.user_metadata.last_name : null),
                    full_name: user.user_metadata.full_name ? user.user_metadata.full_name : user.user_metadata.first_name ? user.user_metadata.first_name : null,
                    preferred_name: (user.user_metadata.first_name ? user.user_metadata.first_name : null),
                    address_1: (user.address_1 ? user.address_1 : null),
                    address_2: (user.address_2 ? user.address_2 : null),
                    city: (user.city ? user.city : null),
                    state_province: (user.state_province ? user.state_province : null),
                    country: (user.country ? user.country : null),
                    status: 'unconfirmed'
                }
                const createdUser = await GraphqlMutations.createUser(userInfo)
                const torkUser = await GraphqlQueries.getLoggedInUserInfo({ email: createdUser.data.insert_users.returning[0].email })
                commit('user', torkUser.data.users[0])
            } catch (err) {
                console.error(err)
            }
        },
        async clearCaches() {
            // try {
            //     caches.keys().then(function (names) {
            //         for (const name of names) { caches.delete(name) }
            //     })
            // } catch (error) {
            //     console.error('error clearing cache: ', error)
            // }
        },
        async getWillCallOrders({ commit, dispatch, state }, eventID) {
            if (!state.fetchingWillCallOrders) {
                commit('fetchingWillCallOrders', true)
                try {
                    const willcall = await GraphqlQueries.getWillCallOrders({ event_id: eventID }).then(result => {
                        if (result.data.errors) {
                            return result.data.errors[0].message
                        } else {
                            return result.data.order_items
                        }
                    })
                    const results = await willcall
                    const data = {
                        eventID,
                        results
                    }
                    commit('willcall', data, eventID)
                    commit('fetchingWillCallOrders', false)
                } catch (err) {
                    Vue.prototype.$buefy.snackbar.open({
                        message: 'Unable to load will call. ' + err,
                        queue: false,
                        type: 'is-warning'
                    })
                }
            }
        }

    }
})