/* eslint-disable no-unused-vars */
import Vue from 'vue'
import { add, parse, parseISO, formatISO } from 'date-fns'
import { zonedTimeToUtc, utcToZonedTime, toDate, format } from 'date-fns-tz'

Vue.filter('toCurrency', function(value) {
    var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2
    })
    return formatter.format(value)
})

Vue.filter('toDollars', function(value) {
    value = value / 100
    var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2
    })
    return formatter.format(value)
})

Vue.filter('toMoney', function(value) {
    if (typeof value === 'number') {
        var formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2
        })
        return formatter.format(value)
    } else {
        return null
    }
})

const formatInTimeZone = (date, fmt, tz) =>
    format(utcToZonedTime(date, tz),
        fmt, { timeZone: tz })

Vue.filter('fullDTTM', dttm => formatInTimeZone(parseISO(dttm), 'yyyy-MM-ddTHH:mm:ss+00:00', 'America/New_York'))
Vue.filter('orderDate', dttm => formatInTimeZone(parseISO(dttm), 'yyyy-MM-dd', 'America/New_York'))
Vue.filter('fullDate', dttm => formatInTimeZone(zonedTimeToUtc(dttm, 'America/New_York'), 'MMMM d, yyyy', 'America/New_York'))
Vue.filter('telemetryDate', dttm => formatInTimeZone(zonedTimeToUtc(dttm, 'America/New_York'), 'MMMM d, yyyy HH:mm:ss', 'America/New_York'))
Vue.filter('year', dttm => formatInTimeZone(zonedTimeToUtc(dttm, 'America/New_York'), 'yyyy', 'America/New_York'))
Vue.filter('date', dttm => dttm ? formatInTimeZone(zonedTimeToUtc(dttm, 'America/New_York'), 'MM/dd/yyyy', 'America/New_York') : '')
Vue.filter('time', dttm => formatInTimeZone(zonedTimeToUtc(dttm, 'America/New_York'), 'hh:mm a z', 'America/New_York'))
Vue.filter('dateAndTime', dttm => formatInTimeZone(zonedTimeToUtc(dttm, 'America/New_York'), 'MM/dd/yyyy hh:mm a', 'America/New_York'))
Vue.filter('timeNoZone', dttm => formatInTimeZone(zonedTimeToUtc(dttm, 'America/New_York'), 'hh:mm a', 'America/New_York'))
Vue.filter('month', dttm => formatInTimeZone(zonedTimeToUtc(dttm, 'America/New_York'), 'MMMM', 'America/New_York'))
Vue.filter('monthabrv', dttm => formatInTimeZone(zonedTimeToUtc(dttm, 'America/New_York'), 'MMM', 'America/New_York'))
Vue.filter('day', dttm => formatInTimeZone(zonedTimeToUtc(dttm, 'America/New_York'), 'd', 'America/New_York'))
Vue.filter('addMonths_1', dttm => formatInTimeZone(add(dttm, { months: 1 }), 'MM/dd/yyyy', 'America/New_York'))
Vue.filter('addMonths_2', dttm => formatInTimeZone(add(dttm, { months: 2 }), 'MM/dd/yyyy', 'America/New_York'))
Vue.filter('addMonths_3', dttm => formatInTimeZone(add(dttm, { months: 3 }), 'MM/dd/yyyy', 'America/New_York'))
Vue.filter('addMonths_4', dttm => formatInTimeZone(add(dttm, { months: 4 }), 'MM/dd/yyyy', 'America/New_York'))
Vue.filter('addMonths_5', dttm => formatInTimeZone(add(dttm, { months: 5 }), 'MM/dd/yyyy', 'America/New_York'))
Vue.filter('start_date', dttm => formatInTimeZone(zonedTimeToUtc(dttm, 'America/New_York'), 'MMMM d', 'America/New_York'))
Vue.filter('end_date', dttm => formatInTimeZone(zonedTimeToUtc(dttm, 'America/New_York'), '- d, yyyy', 'America/New_York'))
Vue.filter('end_date_w_mon', dttm => formatInTimeZone(zonedTimeToUtc(dttm, 'America/New_York'), '- MMMM d, yyyy', 'America/New_York'))
Vue.filter('month_no_tz', dttm => formatInTimeZone(zonedTimeToUtc(dttm, 'America/New_York'), 'MMMM', 'America/New_York'))
Vue.filter('day_no_tz', dttm => formatInTimeZone(zonedTimeToUtc(dttm, 'America/New_York'), 'd', 'America/New_York'))
Vue.filter('year_no_tz', dttm => formatInTimeZone(zonedTimeToUtc(dttm, 'America/New_York'), 'yyyy', 'America/New_York'))

Vue.filter('formatDBName', function(value) {
    value = value.replace(/_/g, ' ')
    const arr = value.split(' ')

    for (var i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1)
    }

    return arr.join(' ')
})