<template>
  <nav v-show="isNavBarVisible" id="navbar-main" class="navbar is-dark has-background-primary" :class="navBarClass">
    <div class="navbar-brand no-negative-margin-left">
      <a @click.prevent="menuToggle" :title="toggleTooltip" class="navbar-item is-desktop-icon-only">
        <b-icon icon="bars" />
      </a>
      <div class="navbar-item has-control mr-4">
        <div class="control">
          <h3 v-if="$route.meta.title" class="has-text-weight-bold is-capitalized">{{ $route.meta.title }}</h3>
          <h3 v-else class="has-text-weight-bold is-capitalized">{{ $route.name }}</h3>
        </div>
      </div>
    </div>
    <div v-if="isLayoutMobile" class="navbar-brand is-right">
      <a class="is-hidden navbar-item navbar-item-menu-toggle" @click.prevent="updatesToggle">
        <b-icon icon="bell" custom-size="default" />
      </a>
      <a class="navbar-item navbar-item-menu-toggle" @click.prevent="menuNavBarToggle">
        <b-icon :icon="menuNavBarToggleIcon" custom-size="default" />
      </a>
    </div>
    <div class="navbar-menu fadeIn animated faster" :class="{ 'is-active': isMenuNavBarActive, 'no-negative-margin-right': isLayoutBoxed }">
      <div class="navbar-start" v-if="$route.meta.subs">
        <router-link
          class="navbar-item"
          exact-path
          tag="a"
          :to="sub.link"
          exact-active-class="is-active has-background-dark"
          v-for="(sub, index) in $route.meta.subs"
          :key="index"
        >
          {{ sub.title }}
        </router-link>
      </div>

      <div class="navbar-end">
        <a
          v-if="cart && $route.path !== '/my/events' && $route.path !== '/profile'"
          @click="showCart()"
          class="navbar-item has-divider has-background-success"
          title="Shopping Cart"
        >
          <b-icon icon="shopping-cart" class="has-text-weight-bold" custom-size="default" />
          <span>({{ cart.length }})</span>
        </a>
        <nav-bar-menu class="has-divider has-user-avatar" :class="{ 'has-background-warning': impersonate }">
          <user-avatar />
          <div class="is-user-name">
            <span v-if="user">{{ user.full_name }}</span>
          </div>

          <div slot="dropdown" class="navbar-dropdown">
            <router-link to="/profile" class="navbar-item" exact-active-class="is-active">
              <b-icon icon="user-alt" custom-size="default" />
              <span>My Profile</span>
            </router-link>
            <a class="navbar-item" title="Log out" @click="logout()">
              <b-icon icon="sign-out-alt" pack="fal" custom-size="default" />
              <span>Log out</span>
            </a>
          </div>
        </nav-bar-menu>
        <a href="/" class="navbar-item has-divider is-desktop-icon-only is-hidden" title="About">
          <b-icon icon="cat-space" custom-size="default" />
          <span>About</span>
        </a>
        <a
          class="navbar-item is-hidden has-divider is-desktop-icon-only"
          :class="{ 'is-active': isAsideRightActive }"
          title="Updates"
          @click.prevent="updatesToggle"
        >
          <b-icon icon="cat-space" custom-size="default" :class="{ 'has-update-mark': hasUpdates }" />
          <span>Updates</span>
        </a>
      </div>
    </div>
  </nav>
</template>

<script>
  import { mapState } from 'vuex'
  import NavBarMenu from '@/components/NavBarMenu'
  import UserAvatar from '@/components/UserAvatar'
  // eslint-disable-next-line no-unused-vars
  import session from '@/utils/session'

  export default {
    name: 'NavBar',
    components: {
      UserAvatar,
      NavBarMenu
    },
    data() {
      return {
        isMenuNavBarActive: false
      }
    },
    computed: {
      ...mapState([
        'user',
        'cart',
        'isLayoutMobile',
        'isLayoutAsideHidden',
        'isLayoutBoxed',
        'isNavBarVisible',
        'navBarColor',
        'isAsideVisible',
        'isAsideExpanded',
        'isAsideMobileExpanded',
        'isAsideRightVisible',
        'isAsideRightActive',
        'hasUpdates',
        'impersonate'
      ]),

      menuNavBarToggleIcon() {
        return this.isMenuNavBarActive ? 'close' : 'ellipsis-v'
      },
      menuToggleIcon() {
        if (this.isLayoutAsideHidden) {
          return this.isAsideVisible ? 'close' : 'menu'
        }

        const isExpanded = this.isLayoutMobile ? this.isAsideMobileExpanded : this.isAsideExpanded

        return isExpanded ? 'backburger' : 'forwardburger'
      },
      toggleTooltip() {
        return this.isAsideExpanded ? 'Collapse' : 'Expand'
      },
      navBarClass() {
        let classAddon = ''

        if (!this.isLayoutBoxed) {
          classAddon += 'is-fixed-top '
        }

        if (this.navBarColor) {
          classAddon += this.navBarColor + ' '
        }

        return classAddon
      }
    },
    mounted() {
      // this.$store.commit('asideStateToggle')
      this.$router.afterEach(() => {
        this.isMenuNavBarActive = false
      })
    },
    methods: {
      clearSessionData() {
        localStorage.clear()
      },
      showCart() {
        this.$store.commit('showCart', true)
      },
      menuToggle() {
        if (this.isLayoutMobile) {
          this.$store.commit('asideMobileStateToggle')
        } else if (this.isLayoutAsideHidden) {
          this.$store.dispatch('asideVisibilityToggle')
        } else {
          this.$store.commit('asideStateToggle')
        }
      },
      menuNavBarToggle() {
        this.isMenuNavBarActive = !this.isMenuNavBarActive
      },
      updatesToggle() {
        this.$store.dispatch('asideRightToggle')
      },
      async logout() {
        await this.$store.dispatch('logout')
      }
    }
  }
</script>
<style>
  #navbar-main .navbar-dropdown a.navbar-item:hover {
    color: #fff !important;
  }
</style>
